<script lang="ts">
  import { onDestroy } from 'svelte'
  import shadows from '../utils/shadows'
  export let text: string
  export let show: boolean
  export let dismissTime = 3000

  let timeout: number

  $: if (show) {
    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      show = false
    }, dismissTime)
  }

  onDestroy(() => {
    window.clearTimeout(timeout)
  })
</script>

<div class:show style={`box-shadow:${shadows[1]}`}>{text}</div>

<style>
  div {
    visibility: hidden;
    opacity: 0;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%) translateY(100px);
    transition: all 0.3s ease-in;
  }
  .show {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }

  :global(.dark) .show {
    box-shadow: none !important;
  }
</style>
