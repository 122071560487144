<script lang="ts">
  import Button from '../components/button/Button.svelte'
  import Dialog from '../components/dialog/Dialog.svelte'
  import Radio from '../components/radio/Radio.svelte'
  import Menu from '../components/menu/Menu.svelte'
  import TextField from '../components/text-field/TextField.svelte'
  import type { Order, OrderStatus } from '../lib/types'
  import { OrderStatusEnum } from '../lib/types'
  import { darkModeStore } from '../stores/darkMode.store'
  import { restaurantStore } from '../stores/restaurant.store'
  import { STATUS_TRANSLATIONS } from '../utils/constants'
  import { t } from 'svelte-i18n'

  export let order: Order
  // export let deleteModalTitle = ''
  export let onEditStatus: (props: { status?: OrderStatus; waiting: number }) => void
  // export let editHref: string

  let showEditWaitingModal = false
  let showEditModal = false
  function closeEditModal() {
    showEditModal = false
    showEditWaitingModal = false
  }

  let errors: { waiting?: boolean; status?: boolean } = {}

  const onSubmitModal: svelte.JSX.FormEventHandler<HTMLFormElement> = (e) => {
    const data = Object.fromEntries(new FormData(e.currentTarget).entries())

    const waiting = Number(data.waiting)
    if (data.waiting && (isNaN(waiting) || waiting <= 0)) {
      errors = { waiting: true }
      return
    }

    const newStatus: OrderStatus = OrderStatusEnum.find((a) => a === data.status)
    if (data.status && !newStatus) {
      errors = { status: true }
      return
    }
    errors = {}

    const oldStatus = order.status
    // update order for printing
    order.status = data.status ? newStatus : order.status
    order.waiting = data.waiting ? waiting : order.waiting

    if (data.status && $restaurantStore.autoPrintTicket && newStatus !== oldStatus && oldStatus === 'validated') {
      setTimeout(onPrint, 0)
    }

    onEditStatus({ status: order.status, waiting: order.waiting })
    closeEditModal()
  }

  function onPrint() {
    const event = new CustomEvent('print-ticket', {
      detail: order,
    })
    window.document.dispatchEvent(event)
  }
  function onPrintKitchen() {
    const event = new CustomEvent('print-ticket-kitchen', {
      detail: order,
    })
    window.document.dispatchEvent(event)
  }

  function onPrintClient() {
    const event = new CustomEvent('print-ticket-client', {
      detail: order,
    })
    window.document.dispatchEvent(event)
  }

  function submitAndPrint() {
    setTimeout(onPrint, 0)
  }

  $: orderStatuses =
    order.delivery === 'shipping'
      ? OrderStatusEnum.filter((e) => e !== 'ready').map((e) => ({ label: $t(STATUS_TRANSLATIONS[e]), value: e }))
      : OrderStatusEnum.filter((e) => e !== 'delivering').map((e) => ({ label: $t(STATUS_TRANSLATIONS[e]), value: e }))

  $: editDisabled = order.status === 'done' || order.status === 'waitingPayment' || order.status === 'failedPayment'
</script>

<div class="flex justify-end" on:click|stopPropagation>
  <Menu className="action-col-menu" icon="more_vert" horizontal="left" vertical="bottom" primary={!!$darkModeStore}>
    <Button
      leftAlign
      on:click={() => {
        showEditModal = true
      }}
      icon="create"
      label={$t('status')}
      primary={!!$darkModeStore}
    />
    <Button
      leftAlign
      on:click={() => (showEditWaitingModal = true)}
      icon="create"
      label={$t('waiting_time')}
      primary={!!$darkModeStore}
    />
    <Button leftAlign icon="print" label={$t('all')} on:click={() => onPrint()} primary={!!$darkModeStore} />
    <Button leftAlign icon="print" label={$t('client')} on:click={() => onPrintClient()} primary={!!$darkModeStore} />
    <Button leftAlign icon="print" label={$t('kitchen')} on:click={() => onPrintKitchen()} primary={!!$darkModeStore} />
  </Menu>

  {#if showEditModal}
    <Dialog ios title={`${$t('order')} #${order.reference}`} content="" on:close={closeEditModal}>
      <div slot="content">
        <form id="order-form" on:submit|preventDefault={onSubmitModal}>
          <!-- <div>
            <TextField
              label="Temps d'attente"
              inputAttributes={{ name: 'waiting', type: 'number', required: true }}
              value={order.waiting || 0}
              invalid={errors.waiting}
            />
          </div> -->

          <!-- <div class="mb-1 mt-2 ">Statut</div> -->
          <Radio value={order.status} name="status" choices={orderStatuses} />
        </form>
      </div>
      <div slot="actions" class="flex align-end">
        <Button label={$t('cancel')} on:click={closeEditModal} primary={!!$darkModeStore} />
        <Button
          buttonAttributes={{ form: 'order-form' }}
          label={$t('validate')}
          type="submit"
          primary={!!$darkModeStore}
        />
      </div>
    </Dialog>
  {/if}

  {#if showEditWaitingModal}
    <Dialog ios title={`${$t('order')} #${order.reference}`} content="" on:close={closeEditModal}>
      <div slot="content">
        <form id="order-form" on:submit|preventDefault={onSubmitModal}>
          <div>
            <TextField
              label={$t('waiting_time')}
              inputAttributes={{ name: 'waiting', type: 'number', required: true, style: 'width: 100%' }}
              value={order.waiting || 0}
              invalid={errors.waiting}
            />
          </div>
        </form>
      </div>
      <div slot="actions" class="flex align-end">
        <Button label={$t('cancel')} on:click={closeEditModal} primary={!!$darkModeStore} />
        <Button
          buttonAttributes={{ form: 'order-form' }}
          label={$t('validate')}
          type="submit"
          primary={!!$darkModeStore}
        />
      </div>
    </Dialog>
  {/if}
</div>

<style>
  :global(.action-col-menu) {
    --button-min-width: 120px;
  }
  :global(.action-col-menu button) {
    margin: 4px;
  }
</style>
