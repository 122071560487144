<script lang="ts">
  import { ripple } from '../ripple/Ripple.svelte'
  import { shadows } from '../utils/shadows'

  export let label = ''
  export let disabled: boolean = false
  export let icon = ''
  export let variant: 'extended' | 'round' = 'round'
  export let size: 'large' | 'small' = 'large'
  export let href = ''
  export let className = ''

  export let vertical: 'top' | 'bottom' = 'bottom'
  export let horizontal: 'right' | 'left' | 'middle' = 'right'

  export let anchorAttributes: svelte.JSX.HTMLAttributes<HTMLAnchorElement> = {}
  export let buttonAttributes: svelte.JSX.HTMLAttributes<HTMLButtonElement> = {}

  $: extended = variant === 'extended'
  $: round = variant === 'round'
  $: large = size === 'large'
  $: small = size === 'small'
  $: top = vertical === 'top'
  $: bottom = vertical === 'bottom'
  $: right = horizontal === 'right'
  $: left = horizontal === 'left'
  $: middle = horizontal === 'middle'
</script>

{#if href}
  <a
    style={!disabled && `box-shadow:${shadows[5]}`}
    href={disabled ? '' : href}
    use:ripple
    class:large
    class:small
    class:extended
    class:round
    class:disabled
    class:top
    class:bottom
    class:left
    class:right
    class:middle
    class={className}
    on:click
    on:focus
    on:blur
    on:mouseenter
    on:mouseout
    on:mousedown
    on:mouseup
    on:mouseover
    {...anchorAttributes}
  >
    {#if icon}<i class="material-icons-round left">{icon}</i>{/if}
    {#if label}{label}{/if}
  </a>
{:else}
  <button
    style={!disabled && `box-shadow:${shadows[5]}`}
    use:ripple
    class:large
    class:small
    class:extended
    class:round
    class:disabled
    disabled={disabled}
    class:top
    class:bottom
    class:left
    class:right
    class:middle
    class={className}
    on:click
    on:focus
    on:blur
    on:mouseenter
    on:mouseout
    on:mousedown
    on:mouseup
    on:mouseover
    {...buttonAttributes}
  >
    {#if icon}<i class="material-icons-round left">{icon}</i>{/if}
    {#if label}{label}{/if}</button>
{/if}

<style>
  button,
  a {
    position: fixed;
    background-color: var(--sms-primary);
    color: var(--sms-on-primary);
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
  }

  i {
    font-size: 24px;
  }
  .round {
    border-radius: 50%;
  }
  .large {
    width: 56px;
    height: 56px;
  }
  .small {
    width: 40px;
    height: 40px;
  }

  .extended.large {
    height: 48px;
  }
  .extended {
    border-radius: 30px;
    padding-right: 20px;
    width: auto;
    white-space: nowrap;
  }
  .extended i {
    padding: 0 12px;
  }

  .top {
    top: 58px;
  }
  .bottom {
    bottom: 16px;
  }
  .right {
    right: 16px;
  }
  .left {
    left: 16px;
  }

  .disabled,
  button[disabled] {
    pointer-events: none;
    background-color: var(--button-disabled-background, #dfdfdf);
    color: var(--button-disabled-color, #9f9f9f);
    cursor: default;
  }

  :global(.dark) button {
    box-shadow: none !important;
  }
</style>
