<script lang="ts">
  import { t } from 'svelte-i18n'

  import Button from '../components/button/Button.svelte'
  import Dialog from '../components/dialog/Dialog.svelte'
  import { darkModeStore } from '../stores/darkMode.store'

  export let deleteModalTitle = ''
  export let onDelete: () => void
  export let editHref: string

  let showDeleteModal = false
  function closeDeleteModal() {
    showDeleteModal = false
  }

  function onClickDelete() {
    onDelete()
    showDeleteModal = false
  }
</script>

{#if editHref}
  <Button icon="create" href={editHref} primary={!!$darkModeStore} />
{/if}

{#if onDelete}
  <Button on:click={() => (showDeleteModal = true)} icon="delete" primary={!!$darkModeStore} />
{/if}
{#if showDeleteModal}
  <Dialog title={$t('delete')} content={deleteModalTitle} on:close={closeDeleteModal}>
    <div slot="actions">
      <Button label={$t('cancel')} on:click={closeDeleteModal} primary={false} className={"secondary"} />
      <Button label={$t('delete')} on:click={onClickDelete} primary={false} className={"secondary"}/>
    </div>
  </Dialog>
{/if}
