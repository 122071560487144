<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import Button from '../button/Button.svelte'

  export let text: string
  export let deletable: boolean = false

  const dispatch = createEventDispatcher<{ delete: string }>()

  function onDelete() {
      
    dispatch('delete', text)
  }
</script>

<span class="chip"><span class="chip-text">{text}</span>
  {#if deletable}
    <span class="btn-ctn">
      <Button on:click={onDelete} variant="contained" size="small" icon="clear" />
    </span>
  {/if}
</span>

<style>
  .chip {
    border-radius: 32px;
    display: inline-flex;
    height: 32px;
    /* text-align: center; */
    align-items: center;
    /* justify-content: center; */
    background-color: var(--sms-primary);
    color: var(--sms-on-primary);
  }
  /* .chip + :global(.chip) {
      margin-left: 8px;
  } */
  .chip-text {
    padding: 0 12px;
    font-size: 15px;
  }
  .btn-ctn {
    display: inline-flex;
    width: 18px;
    height: 18px;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
  }
</style>
