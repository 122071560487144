import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import { DEFAULT_LOCALE, LOCALSTORAGE_LOCALE } from '../utils/constants';
import { getTokenExpired } from '../utils/parse-jwt-token';
import { logError } from './logger';
export const socket = io('__ENV_API_URL');
const client = feathers();
client.hooks({
    before(context) {
        var _a;
        if (!context.params.query) {
            context.params.query = {};
        }
        if (!((_a = context.params.query) === null || _a === void 0 ? void 0 : _a.$lang)) {
            context.params.query.$lang = getLangFromLocalStorage();
        }
    },
    error(context) {
        console.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack);
        if (context.error.code === 401) {
            window.dispatchEvent(new CustomEvent('kool-authenticate-error'));
        }
    },
});
socket.on('connect_error', (error) => {
    console.error('IO connect_error');
    window.dispatchEvent(new CustomEvent('connecterror'));
    logError(error);
});
socket.io.on('error', (error) => {
    console.error('IO error');
    logError(error);
});
socket.io.on('reconnect_error', (error) => {
    console.error('IO reconnect_error');
    logError(error);
});
socket.on('connect', (error) => {
    console.log('socket connected id', socket.id);
    window.dispatchEvent(new CustomEvent('reconnect'));
});
client.configure(socketio(socket));
// Available options are listed in the "Options" section
client.configure(auth({
    storageKey: 'auth',
}));
client.on('login', async () => {
    const { accessToken } = await client.get('authentication');
    const tokenExpired = getTokenExpired(accessToken);
    if (!tokenExpired)
        return;
    const timeoutToExpire = tokenExpired.getTime() - Date.now() + 1000;
    setTimeout(callApiWhenTokenIsExpired, timeoutToExpire);
});
function callApiWhenTokenIsExpired() {
    client.service('products').find();
}
export default client;
function getLangFromLocalStorage() {
    try {
        return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOCALE));
    }
    catch (error) {
        return DEFAULT_LOCALE;
    }
}
