<script lang="ts">
  import { ripple } from '../ripple/Ripple.svelte'
  export let label = ''
  export let disabled: boolean = false
  export let checked: boolean = false
  export let name: string | undefined = undefined
  export let helper: string | undefined = undefined
</script>

<label class:disabled>
  <input name={name} use:ripple type="checkbox" disabled={disabled} bind:checked on:change value="1" />
  <span>{label}</span>
  {#if helper}
  <p class="helper">{helper}</p>
  {/if}
</label>

<style>
  label {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: var(--sms-on-background);
    line-height: 1.5;
    /* min-width: 40px;
    min-height: 40px; */
  }
  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--sms-background);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    user-select: none;
  }
  span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }
  /*Box*/
  span::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    margin: 3px 11px 3px 1px;
    border: solid 2px; /* Safari */
    border-color: var(--sms-on-background);
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
  }
  /*Checkmark*/
  span::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }

  /* Checked, Indeterminate */
  input:checked,
  input:indeterminate {
    background-color: var(--sms-primary);
  }

  input:checked + span::before,
  input:indeterminate + span::before {
    border-color: var(--sms-primary);
    background-color: var(--sms-primary);
  }

  input:checked + span::after,
  input:indeterminate + span::after {
    border-color: var(--sms-on-primary);
  }

  input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
  }

  /* Hover, Focus */
  label:hover > input {
    opacity: 0.04;
  }

  input:focus {
    opacity: 0.12;
    background-color: var(--sms-primary);
  }

  input:hover {
    opacity: 0.12;
    background-color: var(--sms-on-background);
  }
  input:checked:hover {
    opacity: 0.12;
    background-color: var(--sms-primary);
  }

  label:hover > input:focus {
    opacity: 0.16;
  }

  /* Active */
  input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
  }

  input:active + span::before {
    border-color: var(--sms-on-primary);
  }

  input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.6);
  }

  /* Disabled */
  input:disabled {
    opacity: 0;
  }
  input:hover:disabled {
    opacity: 0;
    background-color: none;
  }

  input:disabled + span {
    color: rgba(var(--sms-on-primary-rgb), 0.6);
    cursor: initial;
  }

  input:disabled + span::before {
    border-color: currentColor;
  }

  input:checked:disabled + span::before,
  input:indeterminate:disabled + span::before {
    border-color: transparent;
    background-color: currentColor;
  }

  .helper {
    font-size: 14px;
    margin: 0;
    color: #777;
    font-style: italic;
  }
</style>
