<script lang="ts">
  import { t } from 'svelte-i18n'

  import type { IRestaurant } from '../../lib/types'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import RestaurantForm from './RestaurantForm.svelte'

  let success = false

  async function handleSubmit(e: CustomEvent<IRestaurant>) {
    restaurantIdStore.set(e.detail._id)
    success = true
  }
</script>

{#if success}
  <h1>{$t('help.restaurant_add_1')}</h1>
  <p>
    {$t('help.restaurant_add_2')}
    <a class="link" href="/configuration">{$t('help.restaurant_add_3')} </a>{$t('help.restaurant_add_4')}
    <a class="link" href="/produits">{$t('help.restaurant_add_5')}</a>
  </p>
{:else}
  <h1>{$t('help.restaurant_add_empty')}</h1>
  <RestaurantForm on:success={handleSubmit} />
{/if}

<style>
</style>
