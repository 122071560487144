<script lang="ts">
  import { ripple } from '../ripple/Ripple.svelte'

  export let label: string
  export let icon = ''
  export let active = false
  export let href = ''
</script>

{#if href}
  <a use:ripple href={href} role="listitem" data-close-drawer on:click class:active>
    {#if icon}<i class="material-icons-round">{icon}</i>{/if}
    {label}
  </a>
{:else}
  <button use:ripple role="listitem" data-close-drawer on:click>
    {#if icon}<i class="material-icons-round">{icon}</i>{/if}
    {label}
  </button>
{/if}

<style>
  [role='listitem']:first-child {
    margin-top: 16px;
  }
  a,
  button {
    /*color: var(--sms-on-background);*/
    color: white;
    padding: 4px 4px 4px 16px;
    margin-bottom: 24px;
    border-radius: 4px;
    /*height: 56px;*/
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    transition: 0.5s;
    opacity: 0.7;
  }

  a.active {
    opacity: 1;
  }

  button {
    width: 100%;
  }
  a,
  a:hover {
    font-weight: 300;
    text-decoration: none;
  }
  a:hover,
  button:hover {
    opacity: 1;
  }

  /*a:hover,
  button:hover {
    color: var(--sms-on-primary);
    background-color: rgba(var(--sms-primary-rgb));
  }*/
  i {
    margin: 0 16px 0 0;
  }
</style>
