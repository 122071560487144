<script lang="ts">
  import { fade } from 'svelte/transition'
  import type { IProduct } from '../../lib/types'
  import Snackbar from '../../components/snackbar/Snackbar.svelte'
  import page from 'page'
  import feathers from '../../lib/feathers'
  import ProductForm from './ProductForm.svelte'
  import { onMount } from 'svelte'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { t } from 'svelte-i18n'

  export let id: string

  $: isNew = !id

  let keepInserting = false
  let success = false
  let product: Partial<IProduct> = {
    name: '',
    description: '',
  }

  async function getAndSetProduct() {
    if (isNew) {
      return
    }
    try {
      product = await feathers.service('products').get(id, {
        query: {
          restaurantId: $restaurantIdStore,
        },
      })
    } catch (error) {
      if (error.code === 404) {
        page.redirect('/produits')
      }
    }
  }
  onMount(() => {
    getAndSetProduct()
  })

  function handleSuccess(e: CustomEvent<{ product: IProduct }>) {
    success = true

    if (!keepInserting) {
      page('/produits')
    }
    if (isNew) {
      product = {
        name: '',
        description: '',
        category: e.detail.product.category,
        variants: e.detail.product.variants,
      }
    }
  }
</script>

<div in:fade>
  <ProductForm on:success={handleSuccess} bind:product bind:keepInserting />
</div>

<Snackbar text={$t('form.save_success')} bind:show={success} />

<style>
</style>
