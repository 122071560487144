<script lang="ts">
  import Button from '../button/Button.svelte'
  import shadows from '../utils/shadows'

  export let className = ''
  export let icon = ''
  export let css = ''
  export let label = ''
  export let horizontal: 'left' | 'right' | 'center' = 'left'
  export let vertical: 'top' | 'bottom' | 'center' = 'bottom'
  export let onPrimary: boolean = false
  export let primary: boolean = true
  export let variant: 'outlined' | 'contained' | 'text' = 'text'

  let open

  $: left = horizontal === 'left'
  $: right = horizontal === 'right'
  $: centerH = horizontal === 'center'

  $: top = vertical === 'top'
  $: bottom = vertical === 'bottom'
  $: centerV = vertical === 'center'

  function toggleMenu(e: Event) {
    e.stopPropagation()
    open = !open
  }
  function handleKeydown(event) {
    if (event.key === 'Escape') {
      open = false
    }
  }
</script>

<svelte:window on:click={() => (open = false)} on:keydown={handleKeydown} />
<div class={`sms-menu ctn ${className}`}>
  <Button
    variant={variant}
    icon={icon}
    label={label}
    on:click={toggleMenu}
    onPrimary={onPrimary}
    primary={primary}
    className={css}
  />
  {#if open}
    <div
      class="menu-items"
      class:left
      class:right
      class:centerH
      class:centerV
      class:top
      class:bottom
      style={`box-shadow:${shadows[1]}`}
      on:click={() => (open = false)}
    >
      <slot />
    </div>
  {/if}
</div>

<style>
  .ctn {
    position: relative;
    display: inline-block;
    overflow: visible;
  }

  .menu-items {
    position: absolute;

    display: flex;
    flex-direction: column;
    /* top: calc(100% - 8px); */
    z-index: 500;

    background-color: var(--sms-surface);
    white-space: nowrap;

    max-height: 320px;
    overflow: auto;
  }

  :global(.dark) .menu-items {
    box-shadow: none !important;
  }

  .menu-items.left {
    right: 0;
  }
  .menu-items.right {
    left: 0;
  }
  .menu-items.centerH {
    left: 0;
    transform: translateX(-50%);
  }
  .menu-items.centerV {
    top: 0;
    transform: translateY(-50%);
  }
  .menu-items.centerV.centerH {
    top: 0;
    transform: translateX(-50%) translateY(50%);
  }
  .menu-items.top {
    top: 0;
  }
  .menu-items.bottom {
    bottom: 0;
    transform: translateY(100%);
  }
</style>
