<script lang="ts">
  import type { Paginated } from '@feathersjs/feathers'
  import { onMount } from 'svelte'
  import { t } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import ColumnAction from '../../app-components/ColumnAction.svelte'
  import Button from '../../components/button/Button.svelte'
  import type { IColumn } from '../../components/datatable/Datatable.svelte'
  import Datatable from '../../components/datatable/Datatable.svelte'
  import Fab from '../../components/fab/Fab.svelte'
  import Menu from '../../components/menu/Menu.svelte'
  import MenuItem from '../../components/menu/MenuItem.svelte'
  import TextField from '../../components/text-field/TextField.svelte'
  import feathers from '../../lib/feathers'
  import type { ICategory, IProduct } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { paginationLimitStore } from '../../stores/paginationLimit.store'
  import { restaurantIdStore } from '../../stores/restaurantId.store'

  export let context: PageJS.Context | undefined

  let products: Paginated<IProduct>
  $: limit = $paginationLimitStore
  let page = 1
  let sort = { order: 1, key: 'name' }

  let searchValue = ''
  let query = ''

  $: columns = [
    { key: 'name', header: $t('name'), sortable: true },
    { key: 'description', header: $t('description'), sortable: true },
    {
      key: 'category',
      header: $t('category'),
      sortable: true,
      renderString: (line: IProduct) => (typeof line.category === 'string' ? line.category : line.category?.name),
    },
    { key: 'order', header: $t('columns.order'), sortable: true, defaultValue: '' },
    {
      key: 'archived',
      header: $t('columns.published'),
      sortable: true,
      defaultValue: false,
      renderHtml: (product: IProduct) => {
        const icon = product.archived ? 'unpublished' : 'done'
        return `<span class="material-icons-round mx-auto">${icon}</span>`
      },
    },
    {
      key: '',
      header: '',
      sortable: false,
      width: '100px',
      component: ColumnAction,
      componentProps: (line: IProduct) => ({
        editHref: `/produits/${line._id}`,
        deleteModalTitle: $t('form.ask_delete_$name', { values: { name: line?.name || '' } }),
        onDelete: async () => {
          await feathers.service('products').remove(line._id, {
            query: {
              restaurantId: $restaurantIdStore,
            },
          })
          const data = await fetchData()
          products = data
        },
      }),
    },
  ] as IColumn[]

  let categoryName: string
  $: {
    const urlParams = new URLSearchParams(context?.querystring)
    categoryName = urlParams.get('category')
  }

  $: fetchData = async () => {
    const search: any = {}
    if (query) {
      search.$or = [{ name: { $regex: query, $options: 'i' } }, { description: { $regex: query, $options: 'i' } }]
    }

    const category = categoryName && categories ? categories.data.find((c) => c.name === categoryName) : undefined

    return feathers.service('products').find({
      query: {
        restaurantId: $restaurantIdStore,
        category: category?._id,
        $populate: 'category',
        $sort: { [sort.key]: [sort.order] },
        $limit: limit,
        $skip: (page - 1) * limit,
        ...search,
      },
    }) as Promise<Paginated<IProduct>>
  }

  $: {
    fetchData().then((data) => (products = data))
  }

  function handleSearch() {
    // This will call fetchData()
    query = searchValue
  }

  let categories: Paginated<ICategory>
  async function fetchCategories() {
    const service = feathers.service('categories')
    categories = (await service.find({
      query: { restaurantId: $restaurantIdStore, $limit: 50, $select: 'name' },
    })) as Paginated<ICategory>
  }
  onMount(() => fetchCategories())
</script>

{#if products}
  <div in:fade>
    {#if !products}
      <h3>{$t('help.product_empty')}</h3>
      <Button href="/produits/nouveau" label={$t('begin')} variant="contained" />
    {:else}
      <form on:submit|preventDefault={handleSearch}>
        <div class="relative">
          <TextField
            label={$t('search_by_name_description')}
            className="mb-1"
            fullWidth
            bind:value={searchValue}
            inputAttributes={{ type: 'text' }}
          />
          <span class="absolute" style="top:5px;right:0;width:48px;height:48px">
            <Button icon="search" on:click={handleSearch} primary={false} />
          </span>
        </div>
      </form>
      <Datatable
        bind:page
        bind:limit={$paginationLimitStore}
        bind:sort
        data={products.data}
        columns={columns}
        total={products.total}
        buttonsColor={$darkModeStore ? 'primary' : 'default'}
        largeRow
        paginationText={$t('lines_per_page')}
      />
    {/if}
  </div>
{/if}

<style>
</style>
