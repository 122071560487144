import { writable } from 'svelte-local-storage-store';
import { locale as $locale, getLocaleFromNavigator } from 'svelte-i18n';
import { DEFAULT_LOCALE, LOCALSTORAGE_LOCALE } from '../utils/constants';
import { socket } from '../lib/feathers';
export const localeStore = writable(LOCALSTORAGE_LOCALE, getLocaleFromNavigator() || DEFAULT_LOCALE);
localeStore.subscribe((locale) => {
    if (locale !== 'en' && locale !== 'fr')
        return;
    $locale.set(locale);
    const oneYear = new Date();
    oneYear.setFullYear(oneYear.getFullYear() + 1);
    document.cookie = `KOOL_LOCALE=${locale};domain=__ENV_COOKIE_DOMAIN; expires=${oneYear.toUTCString()}; path=/`;
    // Reconnect socket to refresh cookies !
    if (socket.connected) {
        socket.close();
        socket.connect();
    }
});
