export function ripple(node) {
    node.classList.add('ripple');
    function onClick(e) {
        const rect = node.getBoundingClientRect();
        const x = e.pageX - rect.x;
        const y = e.pageY - rect.y;
        const w = node.offsetWidth;
        const ripple = document.createElement('span');
        ripple.classList.add('ripple-effect');
        ripple.style.left = x + 'px';
        ripple.style.top = y + 'px';
        ripple.style.setProperty('--scale', `${w}`);
        this.appendChild(ripple);
        setTimeout(() => {
            ripple.parentNode.removeChild(ripple);
        }, 500);
    }
    node.addEventListener('mousedown', onClick);
    return {
        destroy() {
            node.classList.remove('ripple');
            node.removeEventListener('click', onClick);
        },
    };
}
