<script lang="ts">
  import { t } from 'svelte-i18n'

  import Button from '../components/button/Button.svelte'
  import Loader from '../components/loader/Loader.svelte'
  import Snackbar from '../components/snackbar/Snackbar.svelte'
  import feathers from '../lib/feathers'
  import { localeStore } from '../stores/locale.store'
  import { userStore } from '../stores/user.store'

  export let accountId: string

  let isLoading = false
  let hasError = false

  async function loginStripeAccount() {
    if (isLoading) return
    isLoading = true

    const { accessToken } = (await feathers.get('authentication')) || {}
    if (!accessToken) {
      userStore.logout()
      return
    }
    const res = await fetch(`__ENV_API_URL/stripe-account-login?$lang=${$localeStore}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({ accountId }),
    })

    isLoading = false
    if (!res.ok) {
      hasError = true
      return
    }

    const { url } = (await res.json()) || {}
    if (!url) {
      hasError = true
      return
    }
    window.open(url, 'blank')
  }
</script>

<div class="flex align-center">
  <Button variant="contained" label={$t('stripe.visit_console')} on:click={loginStripeAccount} />

  {#if isLoading}
    <Loader size="small" />
  {/if}
</div>

<Snackbar bind:show={hasError} text={$t('errors.generic')} />

<style>
</style>
