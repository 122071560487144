import { get, writable } from 'svelte/store';
import feathers from '../lib/feathers';
import { restaurantIdStore } from './restaurantId.store';
function createUserStore() {
    const { subscribe, set, update } = writable(undefined);
    async function login(username, password) {
        var _a, _b, _c;
        const res = await feathers.authenticate({
            strategy: 'local',
            username,
            password,
        });
        const user = getValidUser(res && res.user);
        if (!user)
            return;
        set(user);
        const restaurantId = get(restaurantIdStore);
        if (!((_a = user.restaurants) === null || _a === void 0 ? void 0 : _a.find((r) => r._id === restaurantId))) {
            restaurantIdStore.set(((_c = (_b = user.restaurants) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c._id) || '');
        }
        return user;
    }
    async function reAuthenticate() {
        return feathers
            .reAuthenticate()
            .then((res) => {
            const user = getValidUser(res && res.user);
            if (!user)
                return;
            set(user);
            return user;
        })
            .catch(() => {
            // Fail to reAuthenticate
            set(undefined);
        });
    }
    async function logout() {
        const res = await feathers.logout();
        set(undefined);
    }
    // listen to users adding a restaurant
    feathers.service('users').on('patched', (patchedUser) => {
        update((user) => {
            if (patchedUser._id !== user._id)
                return user;
            return patchedUser;
        });
    });
    feathers.service('restaurants').on('patched', (patchedRestaurant) => {
        update((user) => {
            if (!user.restaurants)
                return user;
            const restaurantIndex = user.restaurants.findIndex((userRestaurant) => userRestaurant._id === patchedRestaurant._id);
            if (restaurantIndex === -1)
                return user;
            user.restaurants[restaurantIndex] = patchedRestaurant;
            return user;
        });
    });
    return {
        subscribe,
        login,
        logout,
        reAuthenticate,
    };
}
export const userStore = createUserStore();
export function getValidUser(json) {
    if (typeof json !== 'object')
        return;
    const user = json;
    if (typeof user.username !== 'string' || typeof user._id !== 'string')
        return;
    return user;
}
