<script lang="ts">
  import FileSaver from 'file-saver'
  import { t } from 'svelte-i18n'

  import Button from '../components/button/Button.svelte'

  import Paper from '../components/paper/Paper.svelte'
  import TextField from '../components/text-field/TextField.svelte'
  import type { IFile } from '../lib/types'

  export let image: IFile | string | undefined
  export let label: string
  export let name: string
  export let className = ''
  export let id = name
  export let errorMessage: string | undefined = undefined

  let size: { width: number; height: number }

  let inputCtn: HTMLInputElement

  function onLoad(e: Event) {
    const image = e.target as HTMLImageElement
    size = { width: image.naturalWidth, height: image.naturalHeight }
  }

  $: hasError = !!errorMessage
  $: src = image
    ? typeof image === 'string'
      ? `__ENV_SCW_URL/${image}?${Date.now()}`
      : `__ENV_SCW_URL/${image._id}?${image.hash || ''}`
    : ''
  $: alt = image ? (typeof image === 'string' ? label : image.name) : ''
  $: fileName = image ? (typeof image === 'string' ? label : image.name) : ''
</script>

<Paper elevation={2} className={`image-container max-w-320 inline-block ${hasError ? 'hasError' : ''} ${className}`}>
  <div class="relative rounded-t" style="height:120px;display: flex;align-items: flex-start;">
    {#if image}
      <img class="rounded-t" width="auto" height="auto" src={src} alt={alt} on:load={onLoad} style="max-width: 100%" />
      <span class="absolute img-btn-ctn">
        <Button
          size="small"
          variant="contained"
          icon="download"
          href={src}
          on:click={(e) => {
            e.preventDefault()
            FileSaver.saveAs(src, fileName)
          }}
        />
      </span>
    {/if}
  </div>

  <h5 class="p-1 my-0">{label}</h5>
  <div class="p-1">
    {#if typeof image === 'object' && image}
      <header>
        <h6 class="my-0 break" style={'min-height: 33px'}>{image.name}</h6>
        <p>
          {image.size}
          {$t('bytes')}
          {#if size}, {size.width}x{size.height}{/if}
        </p>
      </header>
    {/if}
    <div class="max-w-300" bind:this={inputCtn}>
      {#key typeof image === 'object' ? image?._id : image}
        <TextField
          label={$t('choose_image')}
          inputAttributes={{ id, name, type: 'file', accept: 'image/png, image/jpeg, image/webp' }}
        />
      {/key}
    </div>
    {#if errorMessage}
      <div class="text-error">{errorMessage}</div>
    {/if}
  </div>
</Paper>

<style>
  .img-btn-ctn {
    bottom: -8px;
    right: 0px;
  }

  :global(.max-w-320) {
    max-width: 320px;
  }

  .max-w-300 {
    max-width: 300px;
  }

  .break {
    word-break: break-all;
  }

  :global(.hasError) {
    border: solid 2px red;
  }
</style>
