<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { t } from 'svelte-i18n'
  import QrCode from 'svelte-qrcode'
  import type { Order } from '../lib/types'
  import { restaurantStore } from '../stores/restaurant.store'
  import { PAYMENT_TRANSLATIONS } from '../utils/constants'
  import { formatDate } from '../utils/dates'

  let order: Order
  let kitchen: boolean = false
  let client: boolean = false

  $: date = order && formatDate(order.createdAt)

  $: isShipping = order?.delivery === 'shipping'

  $: deliveryLabel = order?.delivery === 'shipping' ? $t('tickets.shipping') : $t('tickets.withdraw')

  $: paymentType = order ? $t(PAYMENT_TRANSLATIONS[order.paymentType]) : ''

  $: instructionsLabel = order?.instructions || '-'

  $: hasAddress = typeof order?.address === 'string' && order?.address && order?.city
  $: hasCoordinates = typeof order?.longitude === 'number' && typeof order?.latitude === 'number'

  function onPrintTicket(event: CustomEvent) {
    kitchen = true
    client = true
    order = event.detail as Order

    window.setTimeout(() => {
      window.print()
      order = undefined
    }, 0)
  }
  function onPrintTicketClient(event: CustomEvent) {
    kitchen = false
    client = true
    order = event.detail as Order
    console.info('order', order)
    window.setTimeout(() => {
      window.print()
      order = undefined
    }, 0)
  }
  function onPrintTicketKitchen(event: CustomEvent) {
    kitchen = true
    client = false
    order = event.detail as Order
    window.setTimeout(() => {
      window.print()
      order = undefined
    }, 0)
  }
  onMount(() => {
    window.document.addEventListener('print-ticket', onPrintTicket)
    window.document.addEventListener('print-ticket-client', onPrintTicketClient)
    window.document.addEventListener('print-ticket-kitchen', onPrintTicketKitchen)
  })
  onDestroy(() => {
    window.document.removeEventListener('print-ticket', onPrintTicket)
    window.document.removeEventListener('print-ticket-client', onPrintTicketClient)
    window.document.removeEventListener('print-ticket-kitchen', onPrintTicketKitchen)
  })
</script>

{#if order}
  <div class="print">
    {#if client}
      <div class="mb-2">
        <div class="mb-2 bold text-xl">{$restaurantStore.name} #{order.reference}</div>
        <div class="mb-2">{$t('tickets.follow_up_id')}: {order.publicId}</div>
        <div class="mb-2">{deliveryLabel} - {date}</div>
        <div class="mb-2">{$t('tickets.estimated_time')}: {order.waiting} {$t('minutes')}</div>
        {#each order.products as p}
          <div class="mb-1">
            {p.productName}{#if p.variantName}- {p.variantName}{/if}:
            {p.variantPrice}€
          </div>
          {#each p.choices as choice}
            <div class="ml-2 mb-1">
              [{choice.choiceQuestion}]
              {#each choice.options as option}
                <div class="ml-2">
                  -
                  {option.optionName}{#if option.optionPrice}: {option.optionPrice}€{/if}
                </div>
              {/each}
            </div>
          {/each}
        {/each}
        <div class="mb-2">{$t('total')}: {order.price}€</div>

        <div class="mb-1">{$t('name')}: {order.name}</div>
        {#if order.phone}
          <div class="mb-1">{$t('phone')}: {order.phone}</div>
        {/if}
        {#if isShipping}
          <div class="mb-1">{$t('address')}: {order.address || '-'}</div>
        {/if}

        <div class="mb-1">{$t('tickets.payment_mean')}: {paymentType}</div>
        {#if order.charge?.id}
          <div class="mb-1">{$t('stripe.stripe')}: {order.charge.id}</div>
        {/if}

        <div>{$t('instructions')}: {instructionsLabel}</div>

        {#if order.delivery === 'shipping'}
          <hr class="my-2" style="" />

          <p class="my-2" style="margin-top:16px; max-width: 275px; text-align: center">{$t('scan_qrcode')}</p>

          <div style="display: flex; justify-content: center;">
            {#if hasAddress}
              <QrCode
                value={`https://www.google.com/maps/dir/${$restaurantStore.address}/${order.address},${order.city}`}
              />
            {:else if hasCoordinates}
              <QrCode
                value={`https://www.google.com/maps/dir/${$restaurantStore.address}/${order.latitude},${order.longitude}`}
              />
            {/if}
          </div>
        {/if}
      </div>
    {/if}
    {#if client && kitchen}
      <hr class="my-2" style="margin-top:30px" />
    {/if}
    {#if kitchen}
      <div>
        <div class="mb-2 bold text-xl">{$restaurantStore.name} #{order.reference} - {$t('kitchen')}</div>
        <div class="mb-2">{deliveryLabel} - {date}</div>
        {#each order.products as p}
          <div class="bold mb-1">{p.productName} - {p.variantName}</div>
          <div class="ml-2">
            {#each p.choices as choice}
              <div class="mb-1">
                <div class="mb-1">[{choice.choiceQuestion}]</div>
                {#each choice.options as option}
                  <div class="ml-2 mb-1">- {option.optionName}</div>
                {/each}
              </div>
            {/each}
          </div>
        {/each}
        <p class="mt-2">{$t('tickets.articles_nb')}: {order.products.length}</p>
        <p class="mt-2">{$t('instructions')}: {instructionsLabel}</p>
      </div>
    {/if}
  </div>
{/if}

<style>
</style>
