<script lang="ts">
  import { t } from 'svelte-i18n'

  import type { Order } from '../lib/types'
  import { restaurantStore } from '../stores/restaurant.store'

  export let order: Order

  $: hasAddress = typeof order.address === 'string' && order.address && order.city
  $: hasCoordinates = typeof order.longitude === 'number' && typeof order.latitude === 'number'
</script>

<div class="flex flex-col inset-panel">
  <div class="p-4">
    <div class="bold text-l pb-1">{$t('contact_details')}</div>
    <div class="pb-2">
      <div class="pb-1">{order.name} - <a class="link" href={`tel:${order.phone}`}>{order.phone}</a></div>
      {#if order.address}
        <div>{order.address}</div>
      {/if}
      {#if order.address2}
        <div>{order.address2}</div>
      {/if}
      {#if order.city}
        <div>
          {order.city}{#if order.postalCode}- {order.postalCode}{/if}
        </div>
      {/if}
      {#if hasAddress}
        <a
          class="link"
          target="blank"
          href={`https://www.google.com/maps/dir/${$restaurantStore.address}/${order.address},${order.city}`}
        >{$t('open_in_googlemap')}</a>
      {:else if hasCoordinates}
        <a
          class="link"
          target="blank"
          href={`https://www.google.com/maps/dir/${$restaurantStore.address}/${order.latitude},${order.longitude}`}
        >{$t('open_in_googlemap')}</a>
      {/if}
    </div>
    <div class="bold text-l">{$t('products')}</div>
    <div class="flex flex-wrap pb-2 flex-col">
      {#each order.products as product}
        <ul>
          <li class="">{product.productName} - {product.variantName} : {product.variantPrice}€</li>

          <ul class="italic">
            {#each product.choices as choice}
              <li>
                {choice.choiceQuestion}
                {#each choice.options as option}
                  <div class="ml-1">
                    {option.optionName}{#if option.optionPrice}- {option.optionPrice}€{/if}
                  </div>
                {/each}
              </li>
            {/each}
          </ul>
        </ul>
      {/each}
    </div>

    <div class="bold text-l pb-1">{$t('delivery_price')}</div>
    <div class="pb-2">{order.deliveryPrice ? order.deliveryPrice.toFixed(2) + '€' : '-'}</div>

    <div class="bold text-l pb-1">{$t('instructions')}</div>
    <div class="pb-2">{order.instructions || '-'}</div>

    <div class="bold text-l pb-1">{$t('public_id')} ({$t('for_follow_up')})</div>
    <div class="pb-2">{order.publicId}</div>

    {#if order.charge}
      <div class="bold text-l pb-1">{$t('stripe_id')}</div>
      <div class="pb-2">{order.charge.id}</div>
    {/if}
  </div>
</div>

<style>
  .inset-panel {
    box-shadow: none;
  }

  :global(:root.dark .inset-panel) {
    background-color: var(--sms-background-inside);
    box-shadow: none;
  }
</style>
