<script lang="ts">
  import type { Paginated } from '@feathersjs/feathers'
  import { createEventDispatcher,onMount } from 'svelte'
  import { t } from 'svelte-i18n'
  import CommonForm from '../../app-components/CommonForm.svelte'
  import InfoText from '../../app-components/InfoText.svelte'
  import InputMultipleText from '../../app-components/InputMultipleText.svelte'
  import Button from '../../components/button/Button.svelte'
  import Checkbox from '../../components/checkbox/Checkbox.svelte'
  import Select from '../../components/select/select.svelte'
  import TextField from '../../components/text-field/TextField.svelte'
  import feathers from '../../lib/feathers'
  import type { ICategory,IChoice,IProduct } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { restaurantIdStore } from '../../stores/restaurantId.store'

  export let keepInserting = false

  const dispatch = createEventDispatcher()

  export let product: Partial<IProduct> = {
    description: '',
    name: '',
  }

  let choices: Paginated<IChoice>
  async function handleSave(data: IProduct) {
    const { _id } = data
    data.restaurantId = $restaurantIdStore
    const service = feathers.service('products')

    const product = _id ? await service.patch(_id, data) : await service.create(data)

    dispatch('success', {
      product,
    })
  }
  let categories: Paginated<ICategory>
  async function fetchCategories() {
    const service = feathers.service('categories')
    categories = (await service.find({ query: { restaurantId: $restaurantIdStore, $limit: 50 } })) as Paginated<
      ICategory
    >
  }
  async function fetchChoices() {
    const service = feathers.service('choices')
    choices = (await service.find({ query: { restaurantId: $restaurantIdStore, $limit: 50 } })) as Paginated<IChoice>
  }

  let scrollTarget: HTMLElement | undefined
  function addVariant() {
    if (!product.variants) product.variants = []
    if (product.variants[product.variants.length - 1]?.name === '') {
      return
    }
    product.variants.push({ name: '', price: 0, choices: [] })
    product = product

    if (scrollTarget && 'scrollIntoView' in scrollTarget) {
      try {
        scrollTarget.scrollIntoView({ behavior: 'smooth' })
      } catch (error) {
        scrollTarget.scrollIntoView()
      }
    }
  }
  function removeVariant(index: number) {
    product.variants = [...product.variants.slice(0, index), ...product.variants.slice(index + 1)]
    product = product
  }

  onMount(() => {
    fetchCategories()
    fetchChoices()
  })

  $: choicesText = choices?.data?.map((c) => c.question)
</script>

<InfoText>
  <div class="mb-1">{$t('help.product_help_1')}</div>
  <div>{$t('help.product_help_2')}</div>
</InfoText>

<CommonForm data={product} bind:keepInserting onSave={handleSave} let:errors>
  <h4 class="mt-4 mb-4 rounded p-2">{$t('form.general_information')}</h4>
  <div class="mb-1">
    <TextField
      label={$t('form.product_name')}
      fullWidth
      inputAttributes={{ name: 'name', required: true }}
      bind:value={product.name}
      invalid={!!errors?.name}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={`${$t('description')} (${$t('form.optionnel')})`}
      fullWidth
      inputAttributes={{ name: 'description' }}
      bind:value={product.description}
      invalid={!!errors?.description}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('form.order_label')}
      inputAttributes={{ type: 'number', required: true }}
      bind:value={product.order}
      invalid={!!errors?.order}
      helper={$t('form.order_label_hint')}
    />
  </div>
  <div class="mt-2">
    {#if categories}
      <Select
        label={$t('categories')}
        bind:value={product.category}
        firstDisabledOption={$t('form.choose_category')}
        selectProps={{ required: true, id: 'select-cat' }}
        options={categories.data.map((e) => ({ text: e.name, value: e._id }))}
        primary={!!$darkModeStore}
      />
    {/if}
  </div>
  <div class="mt-2">
    <Checkbox name="archived" label={$t('form.archived')} bind:checked={product.archived} />
  </div>
  <h4 class="mt-4 mb-4 rounded p-2">{$t('form.variant_management')}</h4>
  <div>
    <Button primary={true} label={$t('form.add_variant')} variant="contained" on:click={addVariant} />
    {#if product.variants && choices}
      {#each product.variants as variant, index}
        <div class="flex align-center mt-2 mb-0 rounded p-2 mb-2 bg-constrast">
          <Button size="small" icon="close" on:click={() => removeVariant(index)} primary={!!$darkModeStore} />
          <span class="ml-1"> {variant.name ? $t('variant') + ': ' + variant.name : $t('form.fill_variant')} </span>
        </div>
        <div class={`flex align-end mt-1 ${index > 0 ? '' : ''}`}>
          <TextField
            inputAttributes={{ name: 'variant-name' }}
            className="min-w-40 mr-2"
            label={$t('name')}
            bind:value={variant.name}
          />
          <TextField
            className="min-w-20 mr-2"
            label={$t('price')}
            bind:value={variant.price}
            inputAttributes={{ type: 'number', min: 0, step: '0.01' }}
          />
        </div>
        <InputMultipleText
          outlined={false}
          datalistId="choices"
          datalist={choicesText}
          name="tags"
          label={$t('the_choices')}
          helper={$t('form.the_choices_hint')}
          tags={choices.data
            .filter((c) => {
              return variant.choices.indexOf(c._id) >= 0
            })
            .map((c) => c.question)}
          onChange={(tags) => {
            variant.choices = choices.data.filter((c) => tags.indexOf(c.question) >= 0).map((c) => c._id)
          }}
        />
      {/each}
      <div bind:this={scrollTarget} />
    {/if}
  </div>
</CommonForm>

{#if choicesText}
  <datalist id="choices">
    {#each choicesText as choice}
      <option value={choice} />
    {/each}
  </datalist>
{/if}

<style>
  :global(.min-w-40) {
    min-width: 40px;
  }
  :global(.min-w-20) {
    min-width: 20px;
  }
</style>
