<script lang="ts">
  import { ripple } from '../ripple/Ripple.svelte'
  export let label = ''
  export let className = ''
  export let iconClassName = ''
  export let disabled: boolean = false
  export let type: 'submit' | 'button' | 'reset' | 'menu' = 'button'
  export let icon = ''
  export let iconPosition: 'left' | 'right' | undefined = undefined
  export let variant: 'outlined' | 'contained' | 'toggle' | 'text' = 'text'
  export let size: 'large' | 'small' | 'default' = 'default'
  export let round: boolean = false

  export let primary: boolean = true
  export let onPrimary: boolean = false
  export let absolute: boolean = false
  export let href = ''

  export let leftAlign: boolean = false

  export let anchorAttributes: svelte.JSX.HTMLAttributes<HTMLAnchorElement> = {}
  export let buttonAttributes: svelte.JSX.HTMLAttributes<HTMLButtonElement> = {}

  $: text = variant === 'text'
  $: contained = variant === 'contained'
  $: outlined = variant === 'outlined'
  $: large = size === 'large'
  $: small = size === 'small'
  $: iconOnly = !label
</script>

{#if href}
  <a
    href={disabled ? '' : href}
    use:ripple
    class={className}
    class:large
    class:small
    class:text
    class:contained
    class:outlined
    class:disabled
    class:iconOnly
    class:round
    class:primary
    class:onPrimary
    class:absolute
    type={type}
    on:click
    on:focus
    on:blur
    on:mouseenter
    on:mouseout
    on:mousedown
    on:mouseup
    on:mouseover
    {...anchorAttributes}
  >
    {#if iconPosition === 'left' || iconOnly || (iconPosition === undefined && icon)}
      <i class={`material-icons-round left ${iconClassName || ''}`} class:iconOnly>{icon}</i>
    {/if}
    {label}
    {#if iconPosition === 'right' && !iconOnly}<i class="material-icons-round right">{icon}</i>{/if}
  </a>
{:else}
  <button
    use:ripple
    class={className}
    class:large
    class:small
    class:text
    class:contained
    class:outlined
    class:disabled
    class:iconOnly
    class:round
    class:onPrimary
    class:primary
    class:absolute
    class:leftAlign
    disabled={disabled}
    type={type}
    on:click
    on:focus
    on:blur
    on:mouseenter
    on:mouseout
    on:mousedown
    on:mouseup
    on:mouseover
    {...buttonAttributes}
  >
    {#if iconPosition === 'left' || iconOnly || (iconPosition === undefined && icon)}
      <i class={`material-icons-round left ${iconClassName || ''}`} class:iconOnly>{icon}</i>
    {/if}
    {label}
    {#if iconPosition === 'right' && !iconOnly}<i class="material-icons-round right">{icon}</i>{/if}
  </button>
{/if}

<style>
  a,
  button {
    line-height: var(--button-height, 2.25rem);
    min-width: var(--button-min-width, 64px);
    font-family: inherit;
    font-weight: 300;
    font-size: 0.875rem;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    vertical-align: middle;
    border-radius: 16px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    /*text-transform: uppercase;*/
    transition: 0.5s;
  }

  button.leftAlign {
    justify-content: left;
  }

  a.absolute,
  button.absolute {
    position: absolute;
  }
  .iconOnly {
    width: 48px;
    height: 48px;
    min-width: 48px;
    text-align: center;
  }
  .iconOnly i {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  .small.iconOnly {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
  .small.iconOnly i {
    font-size: 18px;
    width: 18px;
    height: 18px;
  }

  .large {
    font-size: 1.25rem;
  }

  .iconOnly i.iconOnly {
    margin: 0;
  }

  .round {
    border-radius: 50%;
  }
  .text {
    padding: var(--button-padding, 0 8px);
  }
  .outlined,
  .contained {
    padding: var(--button-padding, 0 16px);
  }
  .text,
  .outlined {
    color: var(--sms-on-background);
    background-color: transparent;
  }

  .text.primary,
  .outlined.primary {
    color: var(--sms-primary);
    background-color: transparent;
  }
  .outlined.primary {
    border-color: var(--sms-primary);
  }
  .outlined {
    padding: 0 15px;
    line-height: var(--button-height, 2.15rem);
    color: var(--sms-on-background);
    background-color: var(--sms-contrast-background);
    border: none;
    box-shadow: 0px 2px 1px -1px rgba(var(--sms-on-background-rgb), 0.2),
      0px 1px 1px 0px rgba(var(--sms-on-background-rgb), 0.14), 0px 1px 3px 0px rgba(var(--sms-on-background-rgb), 0.12);
  }
  i {
    font-size: 18px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top;
  }
  i.left {
    /* margin-left: -4px; */
    margin-right: 8px;
  }
  i.right {
    margin-left: 8px;
    margin-right: -4px;
  }

  .contained.primary {
    background-color: var(--sms-primary);
    color: var(--sms-on-primary, #fff);
  }
  .contained.primary:hover,
  .contained.primary:focus {
    background-color: var(--sms-primary-shade);
  }
  .contained {
    background-color: var(--sms-on-background);
    color: var(--sms-background, #fff);
    transition: 0.5s;
  }
  .contained:hover,
  .contained:focus {
    opacity: 0.8;
  }
  .contained:active,
  .contained:focus {
  }

  text:not(.white):hover {
    color: var(--sms-on-background);
  }

  .outlined:not(.white):hover {
    background-color: rgba(var(--sms-on-background-rgb), 0.09);
  }
  .text.primary:hover,
  .outlined.primary:hover {
    background-color: rgba(var(--sms-primary-rgb), 0.2);
    color: rgba(var(--sms-primary-rgb), 1);
  }
  .text.onPrimary:hover,
  .outlined.onPrimary:hover {
    background-color: rgba(var(--sms-on-primary-rgb), 0.2);
    color: rgba(var(--sms-on-primary-rgb), 1);
  }
  .text:active,
  .outlined:active {
    background-color: rgba(var(--sms-on-background-rgb), 0.15);
  }
  .text.primary:active,
  .outlined.primary:active {
    background-color: rgba(var(--sms-primary-rgb), 0.15);
  }
  .text:focus,
  .outlined:focus {
    background-color: rgba(var(--sms-on-background-rgb), 0.12);
  }

  .text.primary:focus,
  .outlined.primary:focus {
    background-color: rgba(var(--sms-primary-rgb), 0.25);
  }
  .text.onPrimary:focus,
  .outlined.onPrimary:focus {
    background-color: rgba(var(--sms-on-primary-rgb), 0.25);
  }

  .disabled,
  button[disabled] {
    pointer-events: none;
    box-shadow: none;
    background-color: transparent !important;
    color: var(--button-disabled-color, #9f9f9f) !important;
    cursor: default;
    border-color: var(--button-disabled-color, #9f9f9f) !important;
  }

  .contained.disabled,
  .contained[disabled] {
    background-color: var(--button-disabled-background, #dfdfdf) !important;
  }
  .text.onPrimary {
    color: var(--sms-on-primary);
  }

  .white {
    color: white;
  }

  :global(.dark) button {
    box-shadow: none !important;
  }
</style>
