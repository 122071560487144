<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { fade, scale } from 'svelte/transition'

  export let title = ''
  export let content = ''
  export let disableCloseOnClickScrim = false
  export let ios = true

  const dispatch = createEventDispatcher<{ close: void }>()

  function onClickScrim() {
    if (disableCloseOnClickScrim) return
    dispatch('close')
  }

  onMount(() => {
    function closeIfEscape(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        dispatch('close')
      }
    }
    document.addEventListener('keydown', closeIfEscape)
    return () => {
      document.removeEventListener('keydown', closeIfEscape)
    }
  })
</script>

<div
  on:click|stopPropagation
  id="my"
  class="dialog"
  role="alertdialog"
  aria-labelledby="my-label"
  aria-describedby="my-description"
>
  <div class="dialog-ctn" in:scale={{ duration: 200 }} out:fade={{ duration: 200 }}>
    <div class="surface">
      <h2 id="my-label" class="title" class:ios>{title}</h2>
      <section id="my-body" class="content">
        {content}
        <slot name="content" />
      </section>
      <footer class="actions" class:ios>
        <slot name="actions" />
      </footer>
    </div>
  </div>
  <div transition:fade={{ duration: 200 }} class="scrim" on:click={onClickScrim} />
</div>

<style>
  .dialog {
    display: flex;
    z-index: 2000;
  }

  .dialog,
  .scrim {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .dialog-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
    max-width: 400px;
  }

  .surface {
    max-height: calc(100vh - 32px);
    min-width: 280px;
    max-width: 100%;
    background-color: var(--sms-surface, #fff);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .title {
    display: block;
    margin-top: 0;
    line-height: normal;
    font-family: var(--dialog-font-family, var(--sms-font-family, inherit));
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0 24px;
    border-bottom: 1px solid transparent;
  }
  .title.ios {
    border-bottom: solid 1px lightgrey;
    padding-bottom: 12px;
    margin-top: -4px;
    text-align: center;
  }

  .title::before {
    display: inline-block;
    width: 0;
    height: 40px;
    content: '';
    vertical-align: 0;
  }

  .content {
    padding-top: 0;
    color: rgba(var(--sms-on-background-rgb), 0.8);
    font-family: var(--dialog-font-family, var(--sms-font-family, inherit));
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    text-decoration: inherit;
    text-transform: inherit;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 20px 24px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: initial;
  }

  .actions {
    display: flex;
    position: relative;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    min-height: 52px;
    margin: 0;
    padding: 8px;
    border-top: 1px solid transparent;
  }
  .actions.ios {
    border-top: solid 1px lightgrey;
  }

  .scrim {
    background-color: rgba(0, 0, 0, 0.32);
    z-index: -1;
  }

  footer {
    color: #1c2931;
  }

  @media (max-width: 592px) {
    .surface {
      max-width: calc(100vw - 32px);
    }
  }
</style>
