<script lang="ts">
  import { darkModeStore } from '../../stores/darkMode.store'
  import Button from '../button/Button.svelte'

  export let label = ''
  export let helper = ''
  export let inputAttributes: svelte.JSX.HTMLAttributes<HTMLInputElement> = {}
  export let textAreaAttributes: svelte.JSX.HTMLAttributes<HTMLTextAreaElement> = {}
  export let fullWidth: boolean = false
  export let textArea: boolean = false
  export let invalid: boolean = false
  export let readOnly: boolean = false

  export let value: string | number = ''
  export let className = ''
  export let leftIcon = ''
  export let rightIcon = ''

  export let outlined = false
  export let onClickIcon: () => void = undefined

  $: hasButtonIcon = !!onClickIcon
  // $: hasRightIcon = !!leftIcon
  //TODO autoexpand textarea
</script>

{#if textArea}
  <label class={className} class:fullWidth><textarea
      class:invalid
      placeholder=" "
      readonly={readOnly}
      {...textAreaAttributes}
      bind:value
      on:change
      on:blur
      on:input
    /><span>{label}</span></label>
{:else}
  <label class={className} class:outlined class:fullWidth class:rightIcon class:leftIcon class:hasButtonIcon>
    <input
      class:invalid
      placeholder=" "
      readonly={readOnly}
      {...inputAttributes}
      bind:value
      on:change
      on:input
      on:blur
    />
    <span>{label}</span>
    {#if onClickIcon}
      <Button icon={leftIcon} className="button-left" absolute on:click={onClickIcon} primary={!!$darkModeStore} />
    {:else}<i class="material-icons-round left">{leftIcon}</i>{/if}
    <i class="material-icons-round right">{rightIcon}</i>
  </label>
  {#if helper}
  <p class="helper">{helper}</p>
  {/if}
{/if}

<style>
  textarea {
    overflow-y: hidden;
    min-height: 50px;
  }
  label {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background: var(--sms-contrast-background);
    border: none;
  }
  i {
    position: absolute;
    bottom: 14px;
  }

  :global(.button-left) {
    top: 4px;
    left: 0px;
  }

  i.left {
    left: 8px;
  }
  i.right {
    right: 8px;
  }
  .fullWidth {
    width: 100%;
  }

  .invalid,
  .invalid:hover,
  .invalid:focus {
    border-bottom-color: var(--sms-error, #b00020);
  }
  .invalid + span,
  .invalid:focus + span {
    color: var(--sms-error, #b00020);
  }
  .invalid + span::after {
    background-color: var(--sms-error, #b00020);
  }

  /* Input, Textarea */
  input,
  textarea {
    display: block;
    box-sizing: border-box;
    margin: 0;
    border: none;
    border-top: solid 20px transparent;
    border-bottom: solid 1px rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.6);
    padding: 0 16px 8px;
    width: 100%;
    height: 54px;
    color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.87);
    background-color: transparent;
    box-shadow: none; /* Firefox */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    caret-color: rgb(var(--sms-primary-rgb, 33, 150, 243));
    transition: border-bottom 0.2s, background-color 0.2s;
  }

  /* Span */
  input + span,
  textarea + span {
    position: absolute;
    top: -4px;
    left: 0px;
    right: 0;
    bottom: 0;
    display: block;
    box-sizing: border-box;
    padding: 7px 0 0;
    padding-left: 16px;
    color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.6);
    font-size: 75%;
    line-height: 18px;
    pointer-events: none;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
    text-align: left;
  }

  input:placeholder-shown + span,
  textarea:placeholder-shown + span {
    top: -7px;
  }

  [dir='rtl'] input + span,
  [dir='rtl'] textarea + span {
    text-align: right;
  }

  label.leftIcon > input,
  label.leftIcon > span {
    padding-left: 40px;
  }
  label.rightIcon > input,
  label.rightIcon > span {
    padding-right: 40px;
  }

  label.hasButtonIcon > input,
  label.hasButtonIcon > span {
    padding-left: 52px;
  }
  /* Underline */
  input + span::after,
  textarea + span::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(var(--sms-primary-rgb, 33, 150, 243));
    transform-origin: bottom center;
    transform: scaleX(0);
    transition: transform 0.2s;
  }
  input:focus + span::after,
  textarea:focus + span::after {
    background-color: rgb(var(--sms-primary-rgb, 33, 150, 243));
  }

  input:focus ~ i {
    color: rgb(var(--sms-primary-rgb, 33, 150, 243));
  }

  /* Hover */
  label:hover,
  input:hover,
  textarea:hover {
    border-bottom-color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.87);
  }

  /* Placeholder-shown */
  input:not(:focus):placeholder-shown + span,
  textarea:not(:focus):placeholder-shown + span {
    font-size: inherit;
    line-height: 56px;
  }

  /* Focus */
  input:focus,
  textarea:focus {
    outline: none;
  }

  input:focus + span,
  textarea:focus + span {
    color: rgb(var(--sms-primary-shade-rgb, 33, 150, 243));
    top: -4px;
  }

  input:focus + span::before,
  textarea:focus + span::before {
    opacity: 0.12;
  }

  input:focus + span::after,
  textarea:focus + span::after {
    transform: scale(1);
  }

  /* Disabled */
  input:disabled,
  textarea:disabled {
    border-bottom-color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.38);
    color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.38);
  }

  input:disabled + span,
  textarea:disabled + span {
    color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.38);
  }

  .outlined {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 1px solid rgba(var(--sms-on-background-rgb), 0.7);
    padding: 4px;
    padding-left: 8px;
    box-sizing: border-box;
  }

  .outlined input {
    border-bottom: none;
  }
  .outlined input + span {
    padding-left: 8px;
  }

  :global(label:focus + span .iconOnly) {
    color: rgba(var(--sms-primary-rgb), 0.15);
  }

  .helper {
    font-size: 14px;
    margin: 0;
    color: #777;
    font-style: italic;
  }

  /* Faster transition in Safari for less noticable fractional font-size issue */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      input,
      input + span,
      input + span::after,
      textarea,
      textarea + span,
      textarea + span::after {
        transition-duration: 0.1s;
      }
    }
  }
</style>
