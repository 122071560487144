<script lang="ts">
  import { ripple } from '../ripple/Ripple.svelte'

  export let label: string
  export let selected: boolean = false
  export let href = ''

  export let disabled = false
</script>

{#if href}
  <a use:ripple class:disabled class:selected href={disabled ? '' : href} role="listitem" on:click> {label} </a>
{:else}
  <button disabled={disabled} use:ripple class:disabled class:selected role="listitem" on:click> {label} </button>
{/if}

<style>
  a,
  button {
    color: var(--sms-on-background);
    padding: 0 16px;
    min-height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: inherit;
    border: none;
    font-family: inherit;
    font-size: inherit;
    outline-offset: -2px;
  }
  button {
    width: 100%;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  a:hover,
  button:hover {
    color: var(--sms-on-primary);
    background-color: var(--sms-primary);
  }
  .selected {
    background-color: var(--sms-background);
    color: var(--sms-on-primary);
  }

  button.disabled,
  a.disabled {
    pointer-events: none;
    box-shadow: none;
    background-color: transparent !important;
    color: var(--button-disabled-color, #9f9f9f) !important;
    cursor: default;
    border-color: var(--button-disabled-color, #9f9f9f) !important;
  }
</style>
