export function getTokenExpired(token) {
    const parsed = parseToken(token);
    if (!parsed)
        return;
    return new Date(parsed.exp * 1000);
}
function parseToken(token) {
    if (!token)
        return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
}
