export var PaymentType;
(function (PaymentType) {
    PaymentType["cash"] = "cash";
    PaymentType["card"] = "card";
    PaymentType["ezy"] = "ezy";
})(PaymentType || (PaymentType = {}));
export const OrderStatusEnum = ['validated', 'progress', 'delivering', 'ready', 'done'];
export const SHIPPING = 'shipping';
export const WITHDRAW = 'withdraw';
export const DeliveryEnum = [SHIPPING, WITHDRAW];
