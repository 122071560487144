<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { t } from 'svelte-i18n'
  import Dialog from '../components/dialog/Dialog.svelte'
  import LoginForm from './LoginForm.svelte'

  let needReauthenticate = false
  function onAuthenticateError() {
    needReauthenticate = true
  }
  function closeAuthenticateDialog() {
    needReauthenticate = false
  }

  onMount(() => {
    window.addEventListener('kool-authenticate-error', onAuthenticateError)
  })

  onDestroy(() => {
    window.removeEventListener('kool-authenticate-error', onAuthenticateError)
  })
</script>

{#if needReauthenticate}
  <Dialog title={$t('need_reauthenticate')}>
    <div slot="content">
      <LoginForm onSuccess={closeAuthenticateDialog} />
    </div>
  </Dialog>
{/if}
