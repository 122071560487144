<script lang="ts">
  import LoginForm from '../app-components/LoginForm.svelte'
  import { userStore } from '../stores/user.store'

  let username = ''
  let password = ''

  let submitting = false
  let invalid = false
  async function handleSubmit(event) {
    try {
      submitting = true
      invalid = false
      const user = await userStore.login(username, password)
      submitting = false

      if (!user) {
        invalid = true
        return
      }
    } catch (error) {
      console.error(error)
      invalid = true
      submitting = false
    }
  }
</script>

<div class="flex justify-center fill login">
  <aside class="fill flex justify-end items-center">
    <img src="/icons/kool.svg" alt="Logo" width="200" height="53" />
  </aside>
  <main class="fill flex justify-start column">
    <LoginForm />
  </main>
</div>

<style>
  .login {
    min-height: 100vh;
  }

  .fill {
    flex: 1;
  }

  .flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .column {
    flex-direction: column;
  }

  aside {
    background: var(--sms-drawer-color);
    padding-right: 32px;
  }
</style>
