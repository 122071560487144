<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import Backdrop from '../backdrop/Backdrop.svelte'
  // import Drawer, {  Content, Header, Title, Subtitle, Scrim } from '@smui/drawer';
  // import List, { Item, Text } from '@smui/list';

  export let open: boolean = false
  export let auto: boolean = true
  export let variant: 'modal' | 'dismissible' = 'modal'
  export let url = window.location.pathname
  console.info('url', url)
  // TODO export let side : 'right' | 'left' = 'left'

  const dispatch = createEventDispatcher()

  function onClosed() {
    dispatch('closed')
  }

  const onClick: svelte.JSX.MouseEventHandler<HTMLElement> = (e) => {
    if (modal && e.currentTarget.getAttribute('data-close-drawer') !== null) {
      dispatch('closed')
    }
  }

  function listenMediaQuery(mediaQuery: MediaQueryListEvent | MediaQueryList) {
    if (!auto) return

    // BUG in chrome, the matchMedia is called when we print the page !
    // In this cas, document body and window have not the same width, why ? I dont't know.
    if (window.innerWidth < document.body.clientWidth) {
      return
    }
    variant = mediaQuery.matches ? 'dismissible' : 'modal'
  }

  onMount(() => {
    const mediaQuery = window.matchMedia('only screen and (min-width: 768px)')
    listenMediaQuery(mediaQuery)

    mediaQuery.addEventListener('change', listenMediaQuery)
    return () => {
      mediaQuery.removeEventListener('change', listenMediaQuery)
    }
  })

  let clicked = 'nothing yet'

  let active = 'Gray Kittens'

  function setActive(value) {
    active = value
  }
  console.info(url === '/categories')
  $: modal = variant === 'modal'
  $: dismissible = variant === 'dismissible'
  $: showBackdrop = modal && open
  $: showCategoriesButton = url === '/categories'
</script>

<aside class:open class:modal class:dismissible on:click={onClick}>
  <slot />
</aside>

{#if showBackdrop}
  <Backdrop on:click={onClosed} />
{/if}

<!-- 
<Drawer {variant} {open} on:MDCDrawer:closed={onClosed} class="fixed-drawer">
  <Header>
    <Title>Super Drawer</Title>
    <Subtitle>It's the best drawer.</Subtitle>
  </Header>
  <Content class="menu-content">
    <List>
      {#if showCategoriesButton}
       <p>prout</p>
      {/if}
      <Item href="javascript:void(0)" on:click={() => setActive('Gray Kittens')} activated={active === 'Gray Kittens'}>
        <Text>Gray Kittens</Text>
      </Item>
      <Item
        href="javascript:void(0)"
        on:click={() => setActive('A Space Rocket')}
        activated={active === 'A Space Rocket'}
      >
        <Text>A Space Rocket</Text>
      </Item>
      <Item
        href="javascript:void(0)"
        on:click={() => setActive('100 Pounds of Gravel')}
        activated={active === '100 Pounds of Gravel'}
      >
        <Text>100 Pounds of Gravel</Text>
      </Item>
      <Item
        href="javascript:void(0)"
        on:click={() => setActive('All of the Shrimp')}
        activated={active === 'All of the Shrimp'}
      >
        <Text>All of the Shrimp</Text>
      </Item>
      <Item
        href="javascript:void(0)"
        on:click={() => setActive('A Planet with a Mall')}
        activated={active === 'A Planet with a Mall'}
      >
        <Text>A Planet with a Mall</Text>
      </Item>
    </List>
  </Content>
</Drawer>
{#if isModal}
  <Scrim on:click={onClosed} />
{/if} -->
<style>
  aside {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    /*transform: translateX(-250px);*/
    overflow-x: visible;
    background: var(--sms-drawer-color);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    color: white;
    /* padding-top: 56px; */
  }
  aside.modal {
    z-index: 10000;
  }

  aside.open {
    transform: translateX(0);
  }

  :global(html:not(.dark)) aside.open {
    box-shadow: 5px 0 10px 0 rgba(var(--sms-on-background-rgb), 0.2);
  }

  /* The navigation menu links */
  /* aside :global(.sms-drawer-item) {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    height: 64px;
  } */

  aside.dismissible ~ :global(.sms-dismissible-drawer-app-content .app-content) {
    margin-left: 0;
    position: relative;
    transition: margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  aside.dismissible ~ :global(.sms-dismissible-drawer-app-content .navbarFixed .sms-navbar) {
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  aside.dismissible.open ~ :global(.sms-dismissible-drawer-app-content .app-content) {
    margin-left: 275px;
  }
  aside.dismissible.open ~ :global(.sms-dismissible-drawer-app-content .navbarFixed .sms-navbar) {
    --navbar-width: calc(100% - 250px);
  }
</style>
