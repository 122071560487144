<script lang="ts">
  import { t } from 'svelte-i18n'
  import Button from '../components/button/Button.svelte'
  import TextField from '../components/text-field/TextField.svelte'
  import { darkModeStore } from '../stores/darkMode.store'
  import { localeStore } from '../stores/locale.store'
  import { userStore } from '../stores/user.store'

  export let onSuccess = () => {}
  let username = ''
  let password = ''

  let submitting = false
  let invalid = false
  async function handleSubmit(event) {
    try {
      submitting = true
      invalid = false
      const user = await userStore.login(username, password)
      submitting = false

      if (!user) {
        invalid = true
        return
      }
      onSuccess && onSuccess()
    } catch (error) {
      console.error(error)
      invalid = true
      submitting = false
    }
  }
</script>

<div class="login-page flex column">
  <form class="login-form" on:submit|preventDefault={handleSubmit}>
    <TextField bind:value={username} bind:invalid label={$t('username')} fullWidth leftIcon="account_circle" />
    <TextField
      bind:value={password}
      bind:invalid
      label={$t('password')}
      fullWidth
      inputAttributes={{ type: 'password' }}
      leftIcon="vpn_key"
    />
    <div class="flex justify-center">
      <Button label={$t('login')} variant="contained" className="submit" type="submit" bind:disabled={submitting} />
    </div>
  </form>
  <div class="flex justify-center">
    <Button primary={!!$darkModeStore} label="🇬🇧&ensp;English" on:click={() => ($localeStore = 'en')} />
    <Button primary={!!$darkModeStore} label="🇫🇷&ensp;Francais" on:click={() => ($localeStore = 'fr')} />
  </div>
</div>

<style>
  .flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .column {
    flex-direction: column;
  }

  form {
    padding: 16px;
  }

  .login-page {
    margin: auto;
    width: 100%;
    max-width: 768px;
    display: flex;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .login-form :global(label) {
    margin: 8px 0 0 0;
  }
  .login-form :global(label:first-of-type) {
    margin: 0 0 0 0;
  }
  .login-form :global(.submit) {
    margin: 24px 0 0 0;
  }
</style>
