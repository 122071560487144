import { getLocaleFromNavigator, init, register } from 'svelte-i18n';
import { DEFAULT_LOCALE, LOCALSTORAGE_LOCALE } from '../utils/constants';
register('en', () => import('../../locales/en.json'));
register('fr', () => import('../../locales/fr.json'));
init({
    fallbackLocale: DEFAULT_LOCALE,
    initialLocale: getLocaleFromLocalStorage(),
});
function getLocaleFromLocalStorage() {
    if (typeof window === 'undefined')
        return DEFAULT_LOCALE;
    try {
        const locale = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOCALE));
        if (locale === 'en' || locale === 'fr')
            return locale;
        return getLocaleFromNavigator();
    }
    catch (error) {
        return getLocaleFromNavigator();
    }
}
