export function formatDate(date, options) {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    // wrong date
    if (!date.getTime())
        return '';
    const { dateOnly, hoursOnly } = options || {};
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const year = `${date.getFullYear()}`.substr(2);
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    if (dateOnly)
        return `${day}/${month}/${year}`;
    if (hoursOnly)
        return `${hours}:${minutes}`;
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}
function padZero(num) {
    return num >= 10 ? `${num}` : `0${num}`;
}
