<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { t } from 'svelte-i18n'
  import AppLoaderFull from '../../app-components/AppLoaderFull.svelte'
  import EnableStripeButton from '../../app-components/EnableStripeButton.svelte'
  import FormImage from '../../app-components/FormImage.svelte'
  import InputMultipleText from '../../app-components/InputMultipleText.svelte'
  import StripeDashboardLink from '../../app-components/StripeDashboardLink.svelte'
  import Button from '../../components/button/Button.svelte'
  import Checkbox from '../../components/checkbox/Checkbox.svelte'
  import Fab from '../../components/fab/Fab.svelte'
  import Select from '../../components/select/select.svelte'
  import Snackbar from '../../components/snackbar/Snackbar.svelte'
  import TextField from '../../components/text-field/TextField.svelte'
  import feathers from '../../lib/feathers'
  import type { Day,IRestaurant,IRestaurantErrors,ITimeSheet } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { localeStore } from '../../stores/locale.store'
  import { userStore } from '../../stores/user.store'
  import { TimeZones } from '../../utils/constants'

  const dispatch = createEventDispatcher()

  export let restaurant: Partial<IRestaurant> = {
    name: '',
    address: '',
    color: '#FFD448',
    countryCode: '',
    city: '',
    lang: 'fr',
    domain: '',
    phone: '',
    description: '',
    banner: '',
    logo: '',
    defaultWaiting: 60,
    openingTimes: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
    timezone: 'Europe/Paris',
    tags: [],
    deliveryCities: [],
    enableAddressCheck: false,
    requireOnlinePayment: true,
  }

  let errors: IRestaurantErrors = {}
  let errorMessage: string

  $: if (typeof restaurant.openingTimes !== 'object') restaurant.openingTimes = {} as any
  $: if (!restaurant.openingTimes.friday) restaurant.openingTimes.friday = []
  $: if (!restaurant.openingTimes.monday) restaurant.openingTimes.monday = []
  $: if (!restaurant.openingTimes.saturday) restaurant.openingTimes.saturday = []
  $: if (!restaurant.openingTimes.sunday) restaurant.openingTimes.sunday = []
  $: if (!restaurant.openingTimes.thursday) restaurant.openingTimes.thursday = []
  $: if (!restaurant.openingTimes.tuesday) restaurant.openingTimes.tuesday = []
  $: if (!restaurant.openingTimes.wednesday) restaurant.openingTimes.wednesday = []

  function addTime(day: Day) {
    if (restaurant.openingTimes[day]?.length >= 2) return

    restaurant.openingTimes[day] = restaurant.openingTimes[day] || []
    restaurant.openingTimes[day].push({ begin: '', end: '' })

    restaurant.openingTimes = restaurant.openingTimes
  }
  function removeTime(day: Day) {
    if (!restaurant.openingTimes[day].length) return

    restaurant.openingTimes[day].pop()

    restaurant.openingTimes = restaurant.openingTimes
    formHasChanged = true
  }

  function copyTime(day: string, previousDay: string) {
    const previousTime = restaurant.openingTimes[previousDay] as ITimeSheet[]
    if (!previousTime) return
    restaurant.openingTimes[day] = previousTime.map((t) => ({ ...t }))

    restaurant.openingTimes = restaurant.openingTimes
    formHasChanged = true
  }

  $: days = [
    { label: $t('sunday'), key: 'sunday' },
    { label: $t('monday'), key: 'monday' },
    { label: $t('tuesday'), key: 'tuesday' },
    { label: $t('wednesday'), key: 'wednesday' },
    { label: $t('thursday'), key: 'thursday' },
    { label: $t('friday'), key: 'friday' },
    { label: $t('saturday'), key: 'saturday' },
  ] as Array<{
    label: string
    key: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
  }>

  let submitting = false

  const handleSubmit: svelte.JSX.EventHandler<Event, HTMLFormElement> = async (e) => {
    submitting = true

    try {
      errors = {}
      errorMessage = undefined
      const form = e.currentTarget
      const fd = new FormData(form)

      fd.set('acceptOrders', fd.has('acceptOrders') ? '1' : '0')
      fd.set('acceptDelivery', fd.has('acceptDelivery') ? '1' : '0')
      fd.set('acceptCard', fd.has('acceptCard') ? '1' : '0')
      fd.set('autoPrintTicket', fd.has('autoPrintTicket') ? '1' : '0')
      fd.set('enableAddressCheck', fd.has('enableAddressCheck') ? '1' : '0')
      fd.set('requireOnlinePayment', fd.has('requireOnlinePayment') ? '1' : '0')

      const { accessToken } = (await feathers.get('authentication')) || {}

      if (!accessToken) {
        userStore.logout()
        return
      }
      let json: IRestaurant | { errors: IRestaurantErrors; message: string }

      if (restaurant?._id) {
        const res = await fetch(
          `__ENV_API_URL/restaurants/${restaurant._id}?$populate[]=banner&$populate[]=logo&$lang=${$localeStore}`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            credentials: 'include',
            body: fd,
          }
        )
        json = await res.json()
      } else {
        json = await feathers.service('restaurants').create(restaurant)
      }

      if ('errors' in json && json.errors) {
        submitting = false
        errors = { ...json.errors }
        errorMessage = json.message
        return
      }
      restaurant = json as IRestaurant

      formHasChanged = false
      submitting = false

      dispatch('success', restaurant)
    } catch (error) {
      submitting = false
      console.error('error', error)

      errors = { ...error?.errors }
      errorMessage = error.message
    }
  }

  let loadingCountryCode = !!restaurant.countryCode
  let codeCountryExist = false
  let countryApi: { name: string; alpha2Code: string; translations: { fr: string } }

  async function validateCountryCode() {
    if (!restaurant.countryCode) return
    if (restaurant.countryCode?.length !== 2) {
      codeCountryExist = false
      countryApi = undefined
      loadingCountryCode = false
      return
    }

    const url = `https://restcountries.com/v2/alpha/${restaurant.countryCode}?fields=name,alpha2Code,translations`
    try {
      loadingCountryCode = true

      const res = await fetch(url)
      codeCountryExist = res.ok || res.status === 200
      countryApi = await res.json()
      if (errors?.countryCode) errors.countryCode = false
      loadingCountryCode = false
    } catch (error) {
      codeCountryExist = false
      loadingCountryCode = false
    }
  }
  $: validateCountryCode()

  $: hasErrors = !!errorMessage || !!Object.keys(errors)?.length

  let formHasChanged = false

  let slugHasBeenTouched = false

  function cleanSlug(slug: string) {
    return slug
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9\-_]/g, '')
  }
  function onSlugInput() {
    slugHasBeenTouched = true
    restaurant.slug = cleanSlug(restaurant.slug)
  }
  function onNameInput() {
    if (slugHasBeenTouched) return
    const slug = cleanSlug(restaurant.name)
    restaurant.slug = slug
  }

  $: isNewRestaurant = !restaurant?._id

  if (!restaurant.color) {
    restaurant.color = '#ffd600'
  }

  $: isLoading = submitting || loadingCountryCode
</script>

{#if isLoading}
  <AppLoaderFull />
{/if}

<form
  on:input={() => (formHasChanged = true)}
  on:submit|preventDefault={handleSubmit}
  enctype="multipart/form-data"
  method="post"
>
  <h4 class="mb-4 rounded p-2 mt-0">{$t('form.fast_controls')}</h4>
  <div>
    <Checkbox
      name="acceptOrders"
      label={$t('form.allow_orders')}
      bind:checked={restaurant.acceptOrders}
      helper={$t('form.allow_orders_hint')}
    />
  </div>
  <div class="mt-2">
    <Checkbox
      name="acceptDelivery"
      label={$t('form.allow_deliveries')}
      bind:checked={restaurant.acceptDelivery}
      helper={$t('form.allow_deliveries_hint')}
    />
  </div>
  <div class="mt-2">
    {#if restaurant.stripeChargesEnabled}
      <Checkbox
        name="acceptCard"
        label={$t('form.allow_card_payment')}
        bind:checked={restaurant.acceptCard}
        helper={$t('form.allow_card_payment_hint')}
      />
    {:else}
      <Checkbox
        disabled
        name="acceptCard"
        label={$t('form.allow_card_payment')}
        checked={false}
        helper={$t('form.allow_card_payment_hint')}
      />
    {/if}
  </div>
  <div class="mt-2">
    <Checkbox
      name="autoPrintTicket"
      label={$t('form.autoprint_tickets')}
      bind:checked={restaurant.autoPrintTicket}
      helper={$t('form.autoprint_tickets_hint')}
    />
  </div>
  <div class="mt-2">
    <Checkbox
      name="requireOnlinePayment"
      label={$t('form.require_online_payments')}
      bind:checked={restaurant.requireOnlinePayment}
      helper={$t('form.require_online_payments_hint')}
    />
  </div>

  <div class="mt-2 mb-1">
    <TextField
      fullWidth
      label={$t('form.default_waiting_time')}
      inputAttributes={{ name: 'defaultWaiting', type: 'number', required: true }}
      bind:value={restaurant.defaultWaiting}
      invalid={!!errors.defaultWaiting}
      helper={$t('form.default_waiting_time_hint')}
    />
  </div>
  <div class="mb-1 mt-2">
    <TextField
      fullWidth
      label={$t('form.minimum_for_delivery')}
      inputAttributes={{ name: 'minimumAmountForDelivery', type: 'number', required: true }}
      bind:value={restaurant.minimumAmountForDelivery}
      invalid={!!errors.minimumAmountForDelivery}
      helper={$t('form.minimum_for_delivery_hint')}
    />
  </div>

  <div class="mb-1 mt-2">
    <TextField
      fullWidth
      label={$t('form.surcharge_by_product')}
      inputAttributes={{ name: 'productRateForDelivery', type: 'number', required: true, step: '0.01' }}
      bind:value={restaurant.productRateForDelivery}
      invalid={!!errors.productRateForDelivery}
      helper={$t('form.surcharge_by_product_hint')}
    />
  </div>

  <h4 class="mt-4 mb-4  rounded p-2">{$t('form.general_information')}</h4>
  <div class="mb-1">
    <TextField
      label={$t('form.restaurant_name')}
      fullWidth
      readOnly={!isNewRestaurant}
      inputAttributes={{ name: 'name', required: true }}
      bind:value={restaurant.name}
      invalid={!!errors.name}
      on:input={onNameInput}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('form.restaurant_slug')}
      fullWidth
      readOnly={!isNewRestaurant}
      inputAttributes={{ name: 'slug', required: true, pattern: '[a-z0-9-_]+' }}
      bind:value={restaurant.slug}
      on:input={onSlugInput}
      invalid={!!errors.slug}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('description')}
      fullWidth
      inputAttributes={{ name: 'description', required: true }}
      bind:value={restaurant.description}
      invalid={!!errors.description}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('phone')}
      fullWidth
      inputAttributes={{ name: 'phone', required: true }}
      bind:value={restaurant.phone}
      invalid={!!errors.phone}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('address')}
      fullWidth
      inputAttributes={{ name: 'address', type: 'address', required: true }}
      bind:value={restaurant.address}
      invalid={!!errors.address}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={$t('city')}
      fullWidth
      inputAttributes={{ name: 'city', type: 'city', required: true }}
      bind:value={restaurant.city}
      invalid={!!errors.city}
    />
  </div>
  <div class="flex align-end mb-1">
    <TextField
      on:blur={validateCountryCode}
      label={$t('country_code')}
      inputAttributes={{ list: 'countries', name: 'countryCode', type: 'text', required: true }}
      bind:value={restaurant.countryCode}
      invalid={!codeCountryExist || !!errors.countryCode}
    />
  </div>
  <p style="margin-top: 0px;font-style: italic;font-size: 14px;">
    {$t('country')}
    :
    {countryApi?.translations?.fr || ''}
  </p>
  <div class="mb-1">
    <Select
      label={$t('language')}
      bind:value={restaurant.lang}
      selectProps={{ name: 'lang', required: true, id: 'select-lang' }}
      options={[{ text: 'fr', value: 'fr' }, { text: 'en', value: 'en' }]}
      primary={!!$darkModeStore}
    />

    <InputMultipleText
      className={'mt-2'}
      name="deliveryCities"
      label={$t('delivered_cities')}
      bind:tags={restaurant.deliveryCities}
      onChange={() => (formHasChanged = true)}
      helper={$t('form.delivered_cities_hint')}
    />
  </div>

  <h4 class="mt-4 mb-4  rounded p-2">{$t('form.customizations')}</h4>

  {#if !!restaurant && restaurant._id}
    <TextField
      label={$t('domain_name')}
      fullWidth
      readOnly
      inputAttributes={{ name: 'domain', required: false }}
      value={restaurant.domain}
      invalid={!!errors.domain}
      helper={$t('form.domain_name_hint')}
    />
    <InputMultipleText
      className={'mt-2'}
      name="tags"
      label={$t('keywords')}
      helper={$t('form.keywords_hint')}
      bind:tags={restaurant.tags}
      max={10}
      onChange={() => (formHasChanged = true)}
    />

    <TextField
      label={$t('color')}
      inputAttributes={{ name: 'color', required: false, type: 'color', style: 'width:100px' }}
      bind:value={restaurant.color}
      invalid={!!errors.color}
      className={'mt-2'}
      helper={$t('form.color_hint')}
    />

    <h4 class="mt-4 mb-4  rounded p-2">{$t('schedule')}</h4>
    <p>{$t('schedule_description')}</p>

    <div class="mt-2">
      <Select
        label={$t('timezone')}
        bind:value={restaurant.timezone}
        selectProps={{ name: 'timezone', required: true, id: 'select-tz' }}
        options={TimeZones.map((t) => ({ text: t, value: t }))}
        primary={!!$darkModeStore}
      />
    </div>
    {#each days as day, dayIndex}
      <div class="mt-2 mb-1 ml-0 bold">{day.label}</div>
      <div class="mt-1 flex align-center">
        <div class="times">
          {#if !(restaurant.openingTimes[day.key] || [])?.length && (restaurant.openingTimes[days[dayIndex - 1]?.key] || [])?.length}
            <Button
              variant="contained"
              on:click={() => copyTime(day.key, days[dayIndex - 1].key)}
              label={$t('copy_schedule')}
              primary={true}
            />
          {/if}
          {#each restaurant.openingTimes[day.key] || [] as time, index}
            <div class="time">
              <TextField
                className="mr-2"
                fullWidth
                label={$t('start')}
                inputAttributes={{ name: `openingTimes[${day.key}][${index}][begin]`, type: 'time' }}
                bind:value={time.begin}
                invalid={errors[`openingTimes.${day.key}.${index}.begin`]}
              />
              <TextField
                className="mr-2"
                fullWidth
                label={$t('end')}
                inputAttributes={{ name: `openingTimes[${day.key}][${index}][end]`, type: 'time' }}
                bind:value={time.end}
                invalid={errors[`openingTimes.${day.key}.${index}.end`]}
              />
            </div>
          {/each}
        </div>
        <div>
          <Button
            on:click={() => removeTime(day.key)}
            icon={'remove'}
            disabled={!restaurant.openingTimes[day.key]?.length}
            primary={!!$darkModeStore}
          />
          <Button
            on:click={() => addTime(day.key)}
            icon={'add'}
            disabled={restaurant.openingTimes[day.key]?.length >= 2}
            primary={!!$darkModeStore}
          />
        </div>
      </div>
    {/each}
    <h4 class="mt-4 mb-4  rounded p-2">{$t('images')}</h4>
    <div class="flex mb-1">
      <FormImage
        errorMessage={errors.banner}
        name="banner"
        image={restaurant.banner}
        label={$t('banner')}
        className={'mr-2'}
      />
      <FormImage errorMessage={errors.logo} name="logo" image={restaurant.logo} label={$t('logo')} />
    </div>
    {#if restaurant.countryCode?.toUpperCase() === 'FR' || restaurant.countryCode?.toUpperCase() === 'SE'}
      <h4 class="mt-4 mb-4  rounded p-2">{$t('card_payments')}</h4>
      <p>
        {$t('help.restaurant_stripe_1')}
        <a class="link" href="https://stripe.com" target="blank">{$t('stripe.stripe')}</a>
        {$t('help.restaurant_stripe_2')}
      </p>
      <p>{$t('help.restaurant_stripe_3')}</p>

      {#if restaurant.stripeAccountId}
        <ul>
          <li class="mb-1">{$t('stripe_id')}: {restaurant.stripeAccountId}</li>

          <li>{$t('card_payments')}: {restaurant.stripeChargesEnabled ? $t('allowed') : $t('inactive')}</li>
        </ul>
        {#if restaurant.stripeDetailsSubmitted || restaurant.stripeChargesEnabled}
          <StripeDashboardLink accountId={restaurant.stripeAccountId} />
        {:else}
          <div>
            <p>{$t('help.restaurant_stripe_4')}</p>
          </div>
          <EnableStripeButton reActivate />
        {/if}
      {:else}
        <div class="mb-1">
          <p>{$t('help.restaurant_stripe_5')}</p>
          <p>{$t('help.restaurant_stripe_6')}</p>
        </div>
        <EnableStripeButton />
      {/if}
    {/if}

    <h4 class="mt-4 mb-4  rounded p-2">{$t('help.address_geocoding_title')}</h4>

    <p>{$t('help.address_geocoding_1')}</p>
    {#if restaurant.countryCode?.toUpperCase() === 'FR'}
      <p class="bold">{$t('help.address_geocoding_free')}</p>
    {:else}
      <p>{$t('help.address_geocoding_price_$price', { values: { price: '0.05 EUR' } })}</p>
    {/if}
    <Checkbox
      name="enableAddressCheck"
      label={$t('form.enable_address_check')}
      bind:checked={restaurant.enableAddressCheck}
    />
  {/if}

  <Fab icon="save" variant="round" disabled={submitting || !formHasChanged} className="save" />
</form>

<Snackbar text={errorMessage || $t('errors.generic')} show={hasErrors} />

<style>
  .times {
    width: calc(100% - 16px);
  }
  .time {
    display: flex;
  }

  :global(.submit-btn) {
    margin: 16px 0;
    border-radius: 18px;
  }

  .w-250 {
    width: 250px;
  }

  :global(.save) {
    top: 88px !important;
    z-index: 1111 !important;
    left: 223px !important;
    border-radius: 30% !important;
    width: 50px !important;
    height: 50px !important;
  }

  @media (min-width: 600px) {
    .times {
      width: auto;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    .time {
      flex: 0.4;
    }
  }
</style>
