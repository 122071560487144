<script lang="ts">
  import { fade } from 'svelte/transition'
  import type { IChoice } from '../../lib/types'
  import Snackbar from '../../components/snackbar/Snackbar.svelte'
  import page from 'page'
  import feathers from '../../lib/feathers'
  import ChoiceForm from './ChoiceForm.svelte'
  import { onMount } from 'svelte'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { t } from 'svelte-i18n'

  export let id: string

  $: isNew = !id

  let keepInserting = false
  let success = false
  let choice: Partial<IChoice> = {
    question: '',
    min: 0,
    max: 0,
    options: [],
  }

  async function getAndSetData() {
    if (isNew) {
      return
    }
    try {
      choice = await feathers.service('choices').get(id, {
        query: {
          restaurantId: $restaurantIdStore,
        },
      })
    } catch (error) {
      if (error.code === 404) {
        page('/choix')
      }
    }
  }
  onMount(() => {
    getAndSetData()
  })

  function handleSuccess(e: CustomEvent<IChoice>) {
    success = true

    if (!keepInserting) {
      page('/choix')
    }
    if (isNew) {
      choice = {
        question: '',
        min: 0,
        max: 0,
        options: e.detail.options,
      }
    }
  }
</script>

<div in:fade>
  <ChoiceForm on:success={handleSuccess} bind:choice bind:keepInserting />
</div>

<Snackbar text={$t('form.save_success')} bind:show={success} />

<style>
</style>
