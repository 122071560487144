import { derived } from 'svelte/store';
import feathers from '../lib/feathers';
import { restaurantIdStore } from './restaurantId.store';
import { userStore } from './user.store';
export const restaurantStore = derived([restaurantIdStore, userStore], ([$restaurantId, $userStore], set) => {
    var _a;
    if (!$userStore) {
        set(undefined);
        return;
    }
    if (!((_a = $userStore.restaurants) === null || _a === void 0 ? void 0 : _a.find((r) => r._id === $restaurantId))) {
        set(undefined);
        return;
    }
    feathers
        .service('restaurants')
        .get($restaurantId, {
        query: {
            $populate: ['banner', 'logo'],
        },
    })
        .then((restaurant) => {
        set(restaurant);
    });
    function onPatch(restaurant) {
        if (restaurant._id !== $restaurantId)
            return;
        set(restaurant);
    }
    feathers.service('restaurants').on('patched', onPatch);
    return () => {
        feathers.service('restaurants').removeListener('patched', onPatch);
    };
}, null);
