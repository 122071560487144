export const TimeZones = ['Europe/Paris', 'Europe/Stockholm', 'Africa/Casablanca', 'Asia/Kuala_Lumpur'];
export const DEFAULT_LOCALE = 'fr';
export const LOCALSTORAGE_LOCALE = 'kool_locale_v2';
export const STATUS_TRANSLATIONS = {
    delivering: 'orderStatus.delivering',
    done: 'orderStatus.done',
    progress: 'orderStatus.progress',
    ready: 'orderStatus.ready',
    validated: 'orderStatus.validated',
    waitingPayment: 'orderStatus.waitingPayment',
    failedPayment: 'orderStatus.failedPayment',
};
export const DELIVERY_TRANSLATIONS = {
    shipping: 'delivery.shipping',
    withdraw: 'delivery.withdraw',
};
export const PAYMENT_TRANSLATIONS = {
    card: 'paymentType.card',
    cash: 'paymentType.cash',
    ezy: 'paymentType.ezy',
};
