<script lang="ts">
  import { t } from 'svelte-i18n'
  import Button from '../components/button/Button.svelte'
  import Checkbox from '../components/checkbox/Checkbox.svelte'
  import Dialog from '../components/dialog/Dialog.svelte'

  export let keepInserting = false
  export let data: any
  export let onSave: (data: any) => Promise<any>
  export let errors: any = {}
  export let errorMessage = ''

  let submitting = false

  async function handleSubmit() {
    submitting = true

    try {
      await onSave(data)

      submitting = false
    } catch (error) {
      submitting = false
      console.error(error)
      errors = { ...error?.errors }

      if (error?.errors) {
        const keys = Object.keys(error.errors)
        errorMessage = $t('errors.error_field_$name', { values: { name: keys.join(',') } })
      } else {
        errorMessage = $t('errors.generic')
      }
    }
  }

  function closeModal() {
    hasErrors = false
  }

  $: keepEditingLael = $t('stay_on_this_page')
  $: hasErrors = !!Object.keys(errors).length
</script>

<form on:submit|preventDefault={handleSubmit}>
  <slot errors={errors} />
  <div class="submit-ctn">
    <Checkbox label={keepEditingLael} bind:checked={keepInserting} />
    <Button label={$t('save')} variant="contained" type="submit" className="submit-btn" bind:disabled={submitting} />
  </div>
</form>

{#if hasErrors}
  <Dialog title={$t('error')} content={errorMessage} on:close={closeModal}>
    <div slot="actions">
      <Button label={$t('close')} on:click={closeModal} primary={false} className={'secondary'} />
    </div>
  </Dialog>
{/if}

<style>
  .submit-ctn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .submit-ctn :global(.submit-btn) {
    margin-right: 16px;
  }
</style>
