import { formatDate } from './dates';
export function renderDateOrHours(order) {
    const today = new Date();
    const createdAt = new Date(order.createdAt);
    if (createdAt.getFullYear() < today.getFullYear() ||
        createdAt.getMonth() < today.getMonth() ||
        createdAt.getDate() < today.getDate()) {
        return formatDate(createdAt, { dateOnly: true });
    }
    return formatDate(createdAt, { hoursOnly: true });
}
export function orderProductsSummary(order) {
    return order.products
        .map((p) => {
        return (p.productName +
            ' ' +
            p.variantName +
            ',' +
            p.choices
                .map((choice) => {
                return choice.options.map((o) => o.optionName).join(', ');
            })
                .join(', '));
    })
        .join('; ');
}
