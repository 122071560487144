<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { t } from 'svelte-i18n'
  import CommonForm from '../../app-components/CommonForm.svelte'
  import InfoText from '../../app-components/InfoText.svelte'
  import TextField from '../../components/text-field/TextField.svelte'
  import feathers from '../../lib/feathers'
  import type { ICategory } from '../../lib/types'
  import { restaurantIdStore } from '../../stores/restaurantId.store'

  export let keepInserting = false

  const dispatch = createEventDispatcher()

  export let category: Partial<ICategory> = {
    description: '',
    name: '',
  }

  async function handleSave(data: ICategory) {
    const { _id } = data
    data.restaurantId = $restaurantIdStore
    const service = feathers.service('categories')

    const category = _id ? await service.patch(_id, data) : await service.create(data)

    dispatch('success', {
      category,
    })
  }
</script>

<CommonForm data={category} bind:keepInserting onSave={handleSave} let:errors>
  <InfoText>
    <div class="mb-1">{$t('help.category_summary')}</div>
    <div>{$t('help.category_description')}</div>
  </InfoText>
  <h4 class="mt-4 mb-4 rounded p-2">{$t('form.general_information')}</h4>
  <div class="mb-1">
    <TextField
      label={$t('form.category_label')}
      fullWidth
      inputAttributes={{ name: 'name', required: true }}
      bind:value={category.name}
      invalid={!!errors?.name}
    />
  </div>
  <div class="mb-1">
    <TextField
      label={`${$t('description')} (${$t('form.optionnel')})`}
      fullWidth
      inputAttributes={{ name: 'description' }}
      bind:value={category.description}
      invalid={!!errors?.description}
    />
  </div>
  <TextField
    label={$t('form.order_label')}
    inputAttributes={{ type: 'number', required: true }}
    bind:value={category.order}
    invalid={!!errors?.order}
    helper={$t('form.order_label_hint')}
  />
</CommonForm>

<style>
</style>
