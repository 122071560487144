<script lang="ts">
  export let variant: 'standard' | 'fixed' = 'standard'
  export let title = ''

  $: navbarFixed = variant === 'fixed'
</script>

<div class:navbarFixed>
  <nav class="sms-navbar">
    <slot name="start" />
    {#if title}
      <!--span class="title">{title}</span-->
    {/if}
    <slot name="end" />
  </nav>
</div>

<style>
  nav {
    color: var(--sms-on-primary, #fff);
    width: var(--navbar-width, 100%);
    height: var(--navbar-height-mobile, 56px);
    line-height: var(--navbar-line-height-mobile, 56px);
    display: flex;
    align-items: center;
    right: 0;
    background: var(--sms-background);
    border-bottom: 1px solid var(--sms-nav-border);
    justify-content: flex-end;
  }
  .navbarFixed {
    position: relative;
    height: var(--navbar-height-mobile, 56px);
    z-index: 1000;
  }
  .navbarFixed nav {
    position: fixed;
  }
  nav :global([slot='start']) {
    margin-left: 4px;
  }
  nav :global([slot='end']) {
    margin-right: 4px;
  }
  nav :global([slot='end'] > *) {
    margin-right: 8px;
  }

  .title {
    padding: 0 20px;
    white-space: nowrap;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    flex: 1;
  }

  @media only screen and (min-width: 601px) {
    nav {
      height: var(--navbar-height, 64px);
      line-height: var(--navbar-line-height, 64px);
    }

    .navbarFixed {
      height: var(--navbar-height, 64px);
    }
  }
</style>
