<script lang="ts">
  import Button from '../components/button/Button.svelte'
  import Chip from '../components/chip/Chip.svelte'
  import Paper from '../components/paper/Paper.svelte'
  import TextField from '../components/text-field/TextField.svelte'
  import { darkModeStore } from '../stores/darkMode.store'

  export let label: string
  export let className: string|undefined=undefined
  export let helper: string|undefined=undefined
  export let name: string
  export let max = 99
  export let tags: string[]

  export let datalistId: string | undefined = undefined
  export let datalist: string[] | undefined = undefined

  export let outlined: boolean = false

  export let onChange: (tags: string[]) => void = () => {}
  let tag = ''

  $: isMaxTag = tags?.length >= max

  function addTag() {
    if (isMaxTag || !tag) {
      return
    }
    if (datalist?.length && datalist.indexOf(tag) === -1) {
      return
    }
    if (tags?.length && tags.indexOf(tag) >= 0) {
      return
    }
    tags = [...(tags || []), tag]

    onChange(tags)

    tag = ''
  }
  function removeTag(e: CustomEvent<string>) {
    tags = tags.filter((t) => t !== e.detail)

    onChange(tags)
  }
</script>

<Paper variant={outlined ? 'outlined' : 'elevation'} className={`my-1 pt-0 ${className}` }>
  <div class="flex align-end m-2 ml-0">
    <TextField label={label} bind:value={tag} inputAttributes={{ form: `form-${name}`, list: datalistId }}/>
    <Button
      disabled={isMaxTag}
      icon="add"
      on:click={addTag}
      buttonAttributes={{ form: `form-${name}` }}
      primary={!!$darkModeStore}
    />
  </div>
  {#if helper}
    <p class="helper">{helper}</p>
  {/if}
  <div class="flex flex-wrap">
    {#if tags}
      {#each tags as tag}
        <span class="mr-1 mt-1">
          <Chip text={tag} deletable on:delete={removeTag} />
        </span>
      {/each}
    {/if}
  </div>
</Paper>

{#if tags?.length}
  {#each tags as tag, index}<input type="hidden" name={`${name}[${index}]`} value={tag} />{/each}
{:else}<input type="hidden" name={`${name}[]`} />{/if}

<form id={`form-${name}`} on:submit|preventDefault={addTag} />

<style>
    .helper {
    font-size: 14px;
    margin: 0;
    color: #777;
    font-style: italic;
  }
</style>