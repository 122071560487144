<script lang="ts">
  import { fade } from 'svelte/transition'
  import type { ICategory } from '../../lib/types'
  import Snackbar from '../../components/snackbar/Snackbar.svelte'
  import page from 'page'
  import feathers from '../../lib/feathers'
  import CategoryForm from './CategoryForm.svelte'
  import { onMount } from 'svelte'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { t } from 'svelte-i18n'

  export let id: string

  $: isNew = !id

  let keepInserting = false
  let success = false
  let category: Partial<ICategory> = {
    name: '',
    description: '',
  }

  async function getAndSetCategory() {
    if (isNew) {
      return
    }
    try {
      category = await feathers.service('categories').get(id, {
        query: {
          restaurantId: $restaurantIdStore,
        },
      })
    } catch (error) {
      if (error.code === 404) {
        page('/categories')
      }
    }
  }
  onMount(() => {
    getAndSetCategory()
  })

  function handleSuccess(e: CustomEvent<ICategory>) {
    success = true

    if (!keepInserting) {
      page('/categories')
      return
    }
    if (isNew) {
      category = {
        name: '',
        description: '',
      }
    }
  }
</script>

<div in:fade>
  <CategoryForm on:success={handleSuccess} bind:category bind:keepInserting />
</div>

<Snackbar text={$t('form.save_success')} bind:show={success} />

<style>
</style>
