<script lang="ts">
  import page from 'page'
  import { onDestroy, onMount } from 'svelte'
  import { isLoading, t } from 'svelte-i18n'
  import OrderTicket from './app-components/OrderTicket.svelte'
  import CreateOrderListener from './app-components/CreateOrderListener.svelte'
  import Button from './components/button/Button.svelte'
  import Categories from './components/categories/Categories.svelte'
  import Dialog from './components/dialog/Dialog.svelte'
  import Drawer from './components/drawer/Drawer.svelte'
  import DrawerItem from './components/drawer/DrawerItem.svelte'
  import Menu from './components/menu/Menu.svelte'
  import MenuItem from './components/menu/MenuItem.svelte'
  import NavBar from './components/nav-bar/NavBar.svelte'
  import Snackbar from './components/snackbar/Snackbar.svelte'

  import './lib/i18n'
  import CategoryEditPage from './pages/categories/CategoryEditPage.svelte'
  import CategoryList from './pages/categories/CategoryListPage.svelte'
  import ChoiceEditPage from './pages/choices/ChoiceEditPage.svelte'
  import ChoiceListPage from './pages/choices/ChoiceListPage.svelte'
  import Login from './pages/Login.svelte'
  import Orders from './pages/orders/OrderListPage.svelte'
  import ProductEditPage from './pages/products/ProductEditPage.svelte'
  import ProductList from './pages/products/ProductListPage.svelte'
  import RestaurantEdit from './pages/restaurants/RestaurantEdit.svelte'
  import RestaurantNew from './pages/restaurants/RestaurantNew.svelte'
  import { darkModeStore } from './stores/darkMode.store'
  import { playSoundStore } from './stores/playSound.store'
  import { restaurantIdStore } from './stores/restaurantId.store'
  import { userStore } from './stores/user.store'
  import ReauthenticateDialog from './app-components/ReauthenticateDialog.svelte'
  import { localeStore } from './stores/locale.store'
  import ProgressBar from './app-components/ProgressBar.svelte'

  let currentPage
  let pageParams
  let pathname
  let unsub
  let hasConnectError = false
  let reconnectSuccess = false
  let menuOpen = false
  let backHref: string | null = null

  let authenticating = true

  let titleKey = 'app'
  $: sound = $playSoundStore

  function toggleSound() {
    $playSoundStore = !$playSoundStore
  }

  function onConnectError() {
    hasConnectError = true
  }

  function closeConnectErrorModal() {
    hasConnectError = false
  }

  function onReconnect() {
    if (hasConnectError) {
      hasConnectError = false
      reconnectSuccess = true
    }
  }

  onMount(() => {
    menuOpen = window.matchMedia('(min-width: 768px)').matches
    window.addEventListener('connecterror', onConnectError)
    window.addEventListener('reconnect', onReconnect)
  })

  onMount(() => {
    page((context, next) => {
      if ($userStore && !$userStore.restaurants?.length && context.path !== '/restaurants/nouveau') {
        page('/restaurants/nouveau')
        return
      }
      pageParams = {}
      backHref = null
      titleKey = 'app'
      next()
    })
    page.redirect('/', '/commandes')
    // page('/', () => (currentPage = Home))
    page('/login', () => (currentPage = Login))

    page('/commandes', () => {
      currentPage = Orders
      titleKey = 'titles.order_list'
      pathname = '/commandes'
    })

    page('/produits', (context) => {
      pageParams.context = context
      currentPage = ProductList
      titleKey = 'titles.product_list'
      pathname = '/produits'
    })
    page('/produits/nouveau', () => {
      backHref = '/produits'
      currentPage = ProductEditPage
      titleKey = 'titles.product_add'
      pathname = '/produits/nouveau'
    })
    page('/produits/:id', (context) => {
      currentPage = ProductEditPage
      pageParams = context.params
      console.info('context.params', context.params)
      backHref = '/produits'
      titleKey = 'titles.product_edit'
      pathname = '/produits/:id'
    })
    page('/categories', () => {
      titleKey = 'titles.category_list'
      currentPage = CategoryList
      pathname = '/categories'
    })
    page('/categories/nouveau', () => {
      currentPage = CategoryEditPage
      backHref = '/categories'
      titleKey = 'titles.category_add'
      pathname = '/categories/nouveau'
    })
    page('/categories/:id', (context) => {
      currentPage = CategoryEditPage
      pageParams = context.params
      backHref = '/categories'
      titleKey = 'titles.category_edit'
      pathname = '/categories/:id'
    })
    page('/choix', () => {
      currentPage = ChoiceListPage
      titleKey = 'titles.choice_list'
      pathname = '/choix'
    })
    page('/choix/nouveau', () => {
      currentPage = ChoiceEditPage
      backHref = '/choix'
      titleKey = 'titles.choice_add'
      pathname = '/choix/nouveau'
    })
    page('/choix/:id', (context) => {
      currentPage = ChoiceEditPage
      pageParams = context.params
      backHref = '/choix'
      titleKey = 'titles.choice_edit'
      pathname = '/choix/:id'
    })

    page('/restaurants/nouveau', () => {
      currentPage = RestaurantNew
      titleKey = 'titles.restaurant_add'
      pathname = '/restaurants/nouveau'
    })
    page('/configuration', (context) => {
      pageParams.context = context
      currentPage = RestaurantEdit
      titleKey = 'titles.restaurant_edit'
      pathname = '/configuration'
    })

    page.start()
  })

  $: title = $isLoading ? '' : $t(titleKey)

  onMount(() => {
    userStore.reAuthenticate().then((user) => {
      authenticating = false
      if (!user) return
    })
  })

  $: if ($userStore?.restaurants?.length && !$restaurantIdStore) {
    restaurantIdStore.set($userStore.restaurants[0]._id)
  }

  $: if ($userStore && !$userStore.restaurants?.length) {
    page('/restaurants/nouveau')
  }

  onDestroy(page.stop)

  function toggleDarkMode() {
    darkModeStore.update((darkMode) => {
      if (darkMode) return ''
      else return 'darkMode'
    })
  }

  function onConfigurationSave() {
    console.info('save')
  }

  onDestroy(() => {
    window.removeEventListener('connecterror', onConnectError)
    window.removeEventListener('reconnect', onReconnect)
  })
</script>

{#if $isLoading}
  <ProgressBar />
{:else if authenticating}
  <ProgressBar />
{:else if $userStore}
  <div class="mobile">
    <img src={'/icons/placeholder.svg'} loading="lazy" alt="Placeholder" width="250" height="166" />
    <p>{$t('The dashboard is accessible from a laptop only')}</p>
  </div>
  <div class="flex column">
    <Drawer bind:open={menuOpen} on:closed={() => (menuOpen = !menuOpen)} variant="dismissible">
      <div class="flex justify-center px-16">
        <img src="/icons/kool.svg" alt="Logo" width="100" height="53" class="mb-16 mt-16" />
      </div>
      {#if pathname === '/categories'}
        <div class="ui-aside__action">
          <a href="/categories/nouveau" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> add </span>
          </a>
        </div>
      {/if}
      {#if pathname?.startsWith('/categories/')}
        <div class="ui-aside__action">
          <a href="/categories" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> arrow_back </span>
          </a>
        </div>
      {/if}
      {#if pathname === '/produits'}
        <div class="ui-aside__action">
          <a href="/produits/nouveau" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> add </span>
          </a>
        </div>
      {/if}
      {#if pathname?.startsWith('/produits/')}
        <div class="ui-aside__action">
          <a href="/produits" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> arrow_back </span>
          </a>
        </div>
      {/if}
      {#if pathname === '/choix'}
        <div class="ui-aside__action">
          <a href="/choix/nouveau" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> add </span>
          </a>
        </div>
      {/if}
      {#if pathname?.startsWith('/choix/')}
        <div class="ui-aside__action">
          <a href="/choix" class="ui-aside__action__a bg-primary hover">
            <span class="material-icons-round black"> arrow_back </span>
          </a>
        </div>
      {/if}
      {#if pathname?.startsWith('/configuration')}
        <div class="ui-aside__action">
          <a on:click={onConfigurationSave} class="ui-aside__action__a bg-primary hover disabled">
            <span class="material-icons-round"> save </span>
          </a>
        </div>
      {/if}
      <!--div class="drawer-head pl-1">
        {#if $userStore.restaurants?.length}
            <Select
              bind:value={$restaurantIdStore}
              primary={!!$darkModeStore}
              options={$userStore.restaurants.map((r) => ({ text: r.name, value: r._id }))}
              css="menu"
            />
        {/if}
        {#if $userStore.restaurants?.length === 0}
          <span class="ml-1">
            <Menu icon="more_vert" horizontal="left" vertical="bottom" primary={!!$darkModeStore} css="white">
              <MenuItem
                disabled={$userStore.restaurants?.length >= 1}
                label={$t('titles.restaurant_add')}
                href="/restaurants/nouveau"
              />
            </Menu>
          </span>
        {/if}
      </div-->
      <!-- <DrawerItem label="Home" href="/" /> -->
      <DrawerItem label={$t('orders')} href="/commandes" icon="receipt" active={pathname === '/commandes'} />
      <DrawerItem
        label={$t('categories')}
        href="/categories"
        icon="category"
        active={pathname?.startsWith('/categories')}
      />
      <DrawerItem
        label={$t('products')}
        href="/produits"
        icon="lunch_dining"
        active={pathname?.startsWith('/produits')}
      />
      <DrawerItem label={$t('choices')} href="/choix" icon="style" active={pathname?.startsWith('/choix')} />
      <DrawerItem
        label={$t('configuration')}
        href="/configuration"
        icon="settings"
        active={pathname === '/configuration'}
      />
    </Drawer>

    <div class="sms-dismissible-drawer-app-content fill">
      <NavBar variant="fixed" title={title}>
        <span slot="start">
          {#if backHref}
            <!--Button icon="arrow_back" round onPrimary href={backHref} /-->
          {:else}
            <!--Button icon="menu" round onPrimary on:click={() => (menuOpen = !menuOpen)} /-->
          {/if}
          {#if pathname === '/produits'}
            <Categories context={pageParams.context} primary={!!$darkModeStore} />
          {/if}
        </span>
        <span slot="end" class="whitespace-nowrap">
          <Button icon={sound ? 'volume_up' : 'volume_off'} round on:click={toggleSound} primary={!!$darkModeStore} />
          <Button icon="dark_mode" round on:click={toggleDarkMode} primary={!!$darkModeStore} />
          <!--Menu icon="account_circle" label={$userStore.username} horizontal="left" vertical="bottom" onPrimary-->
          <Menu icon="account_circle" horizontal="left" vertical="bottom" primary={!!$darkModeStore}>
            <MenuItem label="🇬🇧&ensp;English" on:click={() => ($localeStore = 'en')} />
            <MenuItem label="🇫🇷&ensp;Francais" on:click={() => ($localeStore = 'fr')} />
            <hr class="menu-separator" />
            <MenuItem label={$t('logout')} on:click={userStore.logout} />
          </Menu>
        </span>
      </NavBar>
      {#key $userStore._id}
        <main class="app-content">
          <svelte:component this={currentPage} {...pageParams} />
        </main>
      {/key}
    </div>
  </div>
  <OrderTicket />
  {#if hasConnectError}
    <Dialog title={$t('error')} content={$t('errors.connect')} on:close={closeConnectErrorModal}>
      <div slot="actions">
        <Button label={$t('close')} on:click={closeConnectErrorModal} primary={false} className={'secondary'} />
      </div>
    </Dialog>
  {/if}

  <ReauthenticateDialog />

  {#if reconnectSuccess}
    <Snackbar text={$t('reconnection_success')} bind:show={reconnectSuccess} />
  {/if}

  <CreateOrderListener />
{:else}
  <div class="mobile">
    <img src={'/icons/placeholder.svg'} loading="lazy" alt="Placeholder" width="250" height="166" />
    <p>{$t('The dashboard is accessible from a laptop only')}</p>
  </div>
  <Login />
{/if}

<style>
  .mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 11111;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.95);
    color: white;
    display: flex;
    text-align: center;
  }

  .relative {
    position: relative;
  }

  .storefront {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 900px) {
    .mobile {
      display: none;
    }
  }

  main {
    background-color: var(--sms-background);
    padding: 16px;
    padding-bottom: 80px;
  }

  .px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .flex {
    display: flex;
  }

  .justify-start {
    justify-content: start;
  }

  .drawer-head {
    height: 64px;
    display: flex;
    align-items: center;
    overflow: visible;
  }

  .drawer-head :global(select) {
    flex: 1;
  }
  .drawer-head :global(.sms-menu) {
    margin-left: auto;
    margin-right: 8px;
  }

  .menu-separator {
    width: 80%;
  }

  .white {
    color: white;
  }

  .ui-aside__action {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: transparent;
    border-radius: 30%;
    left: 215px;
    top: 80px;
    padding: 8px;
    background: var(--sms-background);
    z-index: 1;
  }

  .ui-aside__action__a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30%;
    flex: 1;
    height: 50px;
    width: 50px;
    background: var(--sms-primary);
    text-decoration: none;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .disabled {
    pointer-events: none;
    box-shadow: none;
    background-color: var(--button-disabled-background, #dfdfdf);
    color: var(--button-disabled-color, #9f9f9f) !important;
    cursor: default;
    border-color: var(--button-disabled-color, #9f9f9f) !important;
  }

  .disabled i {
    color: #9f9f9f;
  }

  :global(.dark) .aside__action {
    box-shadow: none !important;
  }
</style>
