<script context="module" lang="ts">
  export interface RadioChoice {
    value: string | number
    label: string
    id?: string
  }
</script>

<script lang="ts">
  export let value: string | number
  export let name: string
  export let choices: RadioChoice[]
</script>

{#each choices as choice, index}
  <div class="radio">
    <input
      type="radio"
      name={name}
      id={choice.id || `radio${index}`}
      class="radio__input"
      checked={choice.value === value}
      value={choice.value}
    />
    <label for={choice.id || `radio${index}`} class="radio__label">{choice.label}</label>
  </div>
{/each}

<style>
  .radio {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 10px;
    line-height: 24px;
  }
  .radio__input {
    position: absolute;
    top: 2px;
    left: 0;
    width: 36px;
    height: 20px;
    opacity: 0;
    z-index: 0;
  }
  .radio__label {
    display: block;
    padding: 0 0 0 32px;
    cursor: pointer;
  }
  .radio__label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 2px solid var(--sms-on-background);
    border-radius: 14px;
    z-index: 1;
    transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .radio__label:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 6px;
    width: 8px;
    height: 8px;
    background-color: var(--sms-primary);
    border-radius: 50%;
    z-index: 2;
    transform: scale(0, 0);
    transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .radio__input:checked + .radio__label:before {
    border-color: var(--sms-primary);
  }
  .radio__input:checked + .radio__label:after {
    transform: scale(1, 1);
  }
</style>
