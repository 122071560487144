<script context="module" lang="ts">
  export interface Option {
    value: string | number
    text: string | number
  }
</script>

<script lang="ts">
  export let value: string | number
  export let options: Option[]
  export let firstDisabledOption: string = ''
  export let selectProps: svelte.JSX.HTMLAttributes<HTMLSelectElement> = {}
  export let css: string = ''
  export let label: string = ''
  export let primary = true
</script>

<!-- svelte-ignore a11y-no-onchange -->
<label class="flex column-reverse">
  <select bind:value {...selectProps} on:change class:primary class={css}>
    {#if firstDisabledOption}
      <option value="" selected disabled>{firstDisabledOption}</option>
    {/if}
    {#each options as option}
      <option value={option.value}>{option.text}</option>
    {/each}
  </select>
  <span>{label}</span>
</label>

<style>
  .column {
    flex-direction: column;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  select,
  option,
  label {
    background: var(--sms-contrast-background);
  }

  label span {
    color: rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.6);
    font-size: 75%;
    padding-top: 4px;
    padding-left: 16px;
  }

  select {
    border: none;
    padding-left: 16px;
  }

  select,
  label {
    height: 54px;
  }

  option:not(:disabled) {
    color: var(--sms-on-background);
  }

  select {
    appearance: none;
    cursor: pointer;
    border-bottom: solid 1px rgba(var(--sms-on-surface-rgb, 0, 0, 0), 0.6);

    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;

    color: var(--sms-on-background);
    border-color: var(--sms-on-background);

    outline: none;
  }

  select.primary {
    color: var(--sms-primary);
    border-color: var(--sms-primary);
  }

  select:focus {
    border-color: var(--sms-primary);
  }

  select:focus + span {
    color: var(--sms-primary);
  }

  select.primary:focus {
    border-color: var(--sms-primary);
  }

  select.menu {
    color: white;
    background: transparent;
    border: 1px solid white;
    font-weight: 100;
    width: 100%;
    margin-right: 8px;
  }
</style>
