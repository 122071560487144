<script lang="ts">
  import type { Paginated } from '@feathersjs/feathers'
  import { t } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import ColumnAction from '../../app-components/ColumnAction.svelte'
  import Button from '../../components/button/Button.svelte'
  import type { IColumn } from '../../components/datatable/Datatable.svelte'
  import Datatable from '../../components/datatable/Datatable.svelte'
  import Dialog from '../../components/dialog/Dialog.svelte'
  import Fab from '../../components/fab/Fab.svelte'
  import feathers from '../../lib/feathers'
  import type { IChoice } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { paginationLimitStore } from '../../stores/paginationLimit.store'
  import { restaurantIdStore } from '../../stores/restaurantId.store'

  let choices: Paginated<IChoice>
  $: limit = $paginationLimitStore
  let page = 1
  let sort = { order: 1, key: 'question' }

  let deleteError: string | undefined = undefined
  function closeErrorModal() {
    deleteError = undefined
  }

  $: columns = [
    { key: 'question', header: $t('question'), sortable: true, width: '30%' },
    { key: 'min', header: $t('minimum') },
    { key: 'max', header: $t('maximum') },
    {
      key: 'options',
      header: $t('columns.options_nb'),
      sortable: true,
      renderString: (line: IChoice) => `${line.options.length}`,
    },
    {
      key: '',
      header: '',
      sortable: false,
      width: '100px',
      component: ColumnAction,
      componentProps: (line: IChoice) => ({
        editHref: `/choix/${line._id}`,
        deleteModalTitle: $t('form.ask_delete_$name', { values: { name: line?.question || '' } }),
        onDelete: async () => {
          try {
            await feathers.service('choices').remove(line._id, {
              query: {
                restaurantId: $restaurantIdStore,
              },
            })
            choices = await fetchData()
          } catch (error) {
            const message = error.message
            if (message && typeof message === 'string') {
              deleteError = message
            }
            console.log('Failed to remove', error)
          }
        },
      }),
    },
  ] as IColumn[]

  $: fetchData = async () => {
    return feathers.service('choices').find({
      query: {
        restaurantId: $restaurantIdStore,
        $sort: { [sort.key]: [sort.order] },
        $limit: limit,
        $skip: (page - 1) * limit,
      },
    }) as Promise<Paginated<IChoice>>
  }

  $: {
    fetchData().then((data) => (choices = data))
  }
</script>

{#if choices}
  <div in:fade>
    {#if !choices?.total}
      <h3>{$t('help.choice_empty')}</h3>
      <Button href="/choix/nouveau" label={$t('begin')} variant="contained" />
    {:else}
      <Datatable
        bind:page
        bind:limit={$paginationLimitStore}
        bind:sort
        data={choices.data}
        columns={columns}
        total={choices.total}
        buttonsColor={$darkModeStore ? 'primary' : 'default'}
        largeRow
        paginationText={$t('lines_per_page')}
      />
    {/if}
  </div>
{/if}

{#if deleteError}
  <Dialog title={$t('error')} content={deleteError} on:close={closeErrorModal}>
    <div slot="actions">
      <Button label={$t('ok')} on:click={closeErrorModal} primary={!!$darkModeStore} />
    </div>
  </Dialog>
{/if}

<style>
</style>
