<script lang="ts">
  import { shadows } from '../utils/shadows'
  export let elevation = 0
  export let square: boolean = false
  export let className: string = ''
  export let variant: 'elevation' | 'outlined' = 'elevation'

  $: shadow = variant === 'outlined' ? 'none' : shadows[elevation]

  $: outlined = variant === 'outlined'
</script>

<div style="box-shadow:{shadow}" class={className} class:square class:outlined>
  <slot />
</div>

<style>
  div {
    border-radius: 4px;
  }
  .square {
    border-radius: 0;
  }
  .outlined {
    border: 1px solid rgba(var(--sms-on-background-rgb), 0.7);
  }
</style>
