<script context="module" lang="ts">
  import Button from '../button/Button.svelte'
  import Checkbox from '../checkbox/Checkbox.svelte'

  export interface ISort {
    order: number
    key: string
  }
  export interface IColumn {
    key: string
    header: string
    type?: 'number'
    renderString?: (line: any) => string
    renderHtml?: (line: any) => string
    component?: any
    componentProps?: (line: any) => any
    sortable?: boolean
    sort?: (sort: ISort) => void
    width?: string
    isLink?: boolean
    canOverflow?: boolean
    defaultValue?: string | number
  }
</script>

<script lang="ts">
  
  const limitOptions = [10, 20, 50, 100]

  export let sort: ISort | undefined = undefined
  export let data: any[] = []
  export let columns: IColumn[]
  export let total: number = 0

  export let limit = limitOptions[0]

  export let page = 1
  export let checkedColumns: boolean[] = new Array(columns.length)

  export let noHeaders: boolean = false
  export let layout: 'fixed' | 'auto' = 'auto'
  export let checkable: boolean = false
  export let rowId: string = '_id'
  export let hasDetail: boolean = false

  export let largeRow: boolean = false

  export let paginationText: string = 'Lignes par page'

  export let getRowClass: (line: any) => string = () => ''

  export let detailComponent: any = undefined
  export let detailComponentProps: (line: any) => any = undefined

  export let buttonsColor: 'primary' | 'default' = 'default'

  let allChecked = false

  $: maxPages = Math.ceil(total / limit)

  const handleTopCheckboxClick: svelte.JSX.EventHandler<Event, HTMLInputElement> = (e) => {
    const checked = e.currentTarget.checked
    checkedColumns.fill(checked)
    checkedColumns = checkedColumns
    allChecked = checked
  }

  $: if (allChecked && checkedColumns.some((c) => !c)) {
    allChecked = false
  }
  $: if (!allChecked && checkedColumns.every((c) => c)) {
    allChecked = true
  }

  function sortTable(column: IColumn) {
    if (!column.sortable) return

    const { key } = column
    const nextSort: ISort = { order: key === sort.key ? sort.order * -1 : 1, key }
    sort = nextSort

    if (typeof column.sort === 'function') {
      column.sort(nextSort)
      return
    }
    function asc(a: any, b: any) {
      return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    }
    function desc(a: any, b: any) {
      return b[key] > a[key] ? 1 : a[key] > b[key] ? -1 : 0
    }
    // function asc(a: any, b: any) {
    //   return (a[key] > b[key]) - (a[key] < b[key])
    // }
    // function desc(a: any, b: any) {
    //   return (b[key] > a[key]) - (b[key] < a[key])
    // }
    data = nextSort.order === 1 ? data.sort(asc) : data.sort(desc)
  }

  function onPageNext() {
    if (page >= maxPages) return
    page += 1
  }
  function onPagePrev() {
    if (page === 1) return
    page -= 1
  }
  function onChangeNbRows() {
    page = 1
  }

  const showTooltip: svelte.JSX.EventHandler<MouseEvent, HTMLTableDataCellElement> = (e) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
      e.currentTarget.setAttribute('title', e.currentTarget.innerText)
    }
  }

  let detailLines: any[] = []
  function handleClickDetail(line: any) {
    const index = detailLines.indexOf(line)
    if (index >= 0) {
      detailLines = [...detailLines.slice(0, index), ...detailLines.slice(index + 1)]
      return
    }
    detailLines = [...detailLines, line]
  }

  $: fixed = layout === 'fixed'
  $: auto = layout === 'auto'
</script>

<table class:auto class:fixed class:hasDetail>
  {#if !noHeaders}
    <thead>
      <tr>
        {#if checkable}
          <th class="action-col">
            <Checkbox checked={allChecked} on:change={handleTopCheckboxClick} />
          </th>
        {/if}

        {#if hasDetail}
          <th class="detail-col" />
        {/if}
        {#each columns as column}
          <th
            class="ripple"
            class:sortable={column.sortable}
            class:number={column.type === 'number'}
            on:click={() => sortTable(column)}
            style={`width:${column.width || ''}`}
          >
            {column.header}
            {#if sort?.key === column.key}
              <div class="sort-icon arrow-up" class:reverse={sort.order === -1} />
            {/if}
          </th>
        {/each}
      </tr>
    </thead>
  {/if}
  <tbody>
    {#each data as line, index}
      <tr
        class:checked={checkedColumns[index]}
        class={getRowClass(line)}
        on:click={() => hasDetail && handleClickDetail(line[rowId])}
      >
        {#if checkable}
          <td class="action-col">
            <Checkbox bind:checked={checkedColumns[index]} />
          </td>
        {/if}
        {#if hasDetail}
          <td class="detail-col">
            <Button
              size="small"
              primary={buttonsColor === 'primary'}
              icon="expand_more"
              iconClassName={`rotate no-background ${detailLines.indexOf(line[rowId]) >= 0 ? '' : 'rotate-right'}`}
            />
          </td>
        {/if}
        {#each columns as column, index}
          <td
            class:canOverflow={!!column.canOverflow}
            class:number={column.type === 'number'}
            on:mouseenter={showTooltip}
          >
            {#if column.component}
              <svelte:component this={column.component} {...column.componentProps(line)} />
            {:else if typeof column.renderString === 'function'}
              {column.renderString(line)}
            {:else if typeof column.renderHtml === 'function'}
              {@html column.renderHtml(line)}
            {:else if column.isLink}
              <a style="display:block" href={`/categories/${line[rowId]}`}>{line[column.key]}</a>
            {:else}
              <p class="my-0" style="max-width: 400px; white-space: break-spaces">
                {line[column.key] ?? column.defaultValue}
              </p>
            {/if}
          </td>
        {/each}
      </tr>
      {#if hasDetail && detailLines.indexOf(line[rowId]) >= 0}
        <tr class="detail-row">
          <td colspan={columns.length + (hasDetail ? 1 : 0)}>
            <div>
              <svelte:component this={detailComponent} {...detailComponentProps(line)} />
            </div>
          </td>
        </tr>
      {/if}
    {/each}
  </tbody>
  <tfoot>
    <tr>
      <td colspan={columns.length + (hasDetail ? 1 : 0)}>
        <div class="pagination">
          <span>
            <span class="mr-1">{paginationText}:</span>
            <select bind:value={limit} on:change={onChangeNbRows} class={'p-1'}>
              {#each limitOptions as option}
                <option value={option}>{option}</option>
              {/each}
            </select>
          </span>
          <span>{total === 0 ? 0 : (page - 1) * limit + 1}-{Math.min(total, page * limit)} / {total}</span>
          <span>
            <Button on:click={onPagePrev} icon="chevron_left" primary={buttonsColor === 'primary'} />
            <Button on:click={onPageNext} icon="chevron_right" primary={buttonsColor === 'primary'} />
          </span>
        </div>
      </td>
    </tr>
  </tfoot>
</table>

<style>
  table {
    border-radius: 4px;
    border-spacing: 0;
    font-size: 14px;
    font-family: var(--datatable-font-family, var(--sms-font-family, inherit));
    width: 100%;
    background: var(--sms-background-inside);
    box-shadow: var(--sms-shadow);
    padding: 0 16px;
  }
  table.fixed {
    table-layout: fixed;
  }

  table.auto {
    table-layout: auto;
  }

  th,
  td {
    border-bottom: 1px solid rgba(var(--sms-on-background-rgb), 0.12);
    /*    padding: 8px 16px;*/
    text-align: left;
    /* max-width: 0; */
    /* width: 100%; */
    /* min-width:1px; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  td.canOverflow {
    overflow: visible;
  }
  tfoot tr:first-of-type td {
    border-top: 1px solid rgba(var(--sms-on-background-rgb), 0.12);
  }

  td {
    /*height: 39px;*/
    color: inherit;
  }
  .largeRow td {
    height: 64px;
  }
  td,
  th {
    position: relative;
  }
  td:before {
    content: ' ';
    visibility: hidden;
  }

  th {
    height: 43px;
    color: rgba(var(--sms-th-rgb), 0.87);
    padding-left: 4px;
    padding-right: 4px;
  }

  th.number,
  td.number {
    text-align: right;
  }
  tr.checked {
    background: rgba(var(--sms-on-background-rgb), 0.09);
  }
  tr.checked:hover {
    background: rgba(var(--sms-on-background-rgb), 0.12);
  }
  td:first-of-type.detail-row {
    width: 35px;
  }

  th:first-of-type,
  td:first-of-type {
    padding-left: 8px !important;
  }

  tr td:last-of-type:not(.detail-col, .detail-row td),
  tr th:last-of-type:not(.detail-col) {
    padding-right: 8px;
  }
  table tr:last-of-type td {
    border-bottom: unset;
  }

  th.sortable {
    cursor: pointer;
    position: relative;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .hasDetail tbody tr {
    cursor: pointer;
  }
  tr th:last-of-type.sortable {
    padding-right: 22px;
  }

  th.sortable:hover {
    /*background: rgba(var(--sms-on-background-rgb), 0.12);*/
  }

  tbody tr {
    /*transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);*/
    transition: 0.5s;
  }
  tbody tr:hover {
    /*box-shadow: 0px 3px 1px -2px rgba(var(--sms-on-background-rgb), 0.2),
      0px 2px 2px 0px rgba(var(--sms-on-background-rgb), 0.14), 0px 1px 5px 3px rgba(var(--sms-on-background-rgb), 0.12);*/
    background: var(--sms-contrast-background);
  }
  .sort-icon {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    right: 4px;
    top: calc(50% - 3px);
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(var(--sms-on-background-rgb), 0.6);
  }

  .reverse {
    transform: rotate(180deg);
  }

  .sort-icon {
    position: absolute;
    right: 20px;
  }

  :global(.rotate) {
    transition: transform 0.2s;
  }
  :global(.rotate.rotate-right) {
    transform: rotate(-90deg);
  }

  .pagination {
    font-family: var(--datatable-font-family, var(--sms-font-family, inherit));
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .pagination > * {
    margin: 0 16px;
  }
  .action-col {
    padding: 0;
    padding-left: 10px;
    width: 30px;
  }
  .detail-col {
    width: 20px;
    padding: 0 4px;
  }
  .detail-row td {
    padding: 0px;
    padding-left: 0px !important;
  }

  :global(button.no-hover:hover) {
    background-color: transparent !important;
  }
  :global(button.no-hover:focus) {
    background-color: transparent !important;
    outline: auto;
  }

  select {
    background: var(--sms-background);
    color: var(--sms-on-background);
    border-top: none;
    border-left: none;
    border-right: none;
  }
</style>
