<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { playSoundStore } from '../stores/playSound.store'
  import feathers from '../lib/feathers'
  import Snackbar from '../components/snackbar/Snackbar.svelte'
  import { t } from 'svelte-i18n'

  let newOrder = false
  $: sound = $playSoundStore

  function showNewOrderSnack() {
    newOrder = true
  }

  function onCreateOrder() {
    playOrderSound()
    showNewOrderSnack()
  }

  function playOrderSound() {
    if (!sound) return

    const audio = new Audio('/audio/notification.wav')
    audio.play()
  }

  onMount(() => {
    feathers.service('orders').on('created', onCreateOrder)
  })

  onDestroy(() => {
    feathers.service('orders').removeListener('created', onCreateOrder)
  })

  onMount(() => {
    window.addEventListener('play_order_sound', playOrderSound)
  })

  onDestroy(() => {
    window.removeEventListener('play_order_sound', playOrderSound)
  })
</script>

{#if newOrder}
  <Snackbar text={$t('got_new_order')} bind:show={newOrder} dismissTime={10000} />
{/if}
