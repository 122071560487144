<script lang="ts">
  import { t } from 'svelte-i18n'

  import Button from '../components/button/Button.svelte'
  import Loader from '../components/loader/Loader.svelte'
  import Snackbar from '../components/snackbar/Snackbar.svelte'
  import feathers from '../lib/feathers'
  import { localeStore } from '../stores/locale.store'
  import { restaurantIdStore } from '../stores/restaurantId.store'
  import { userStore } from '../stores/user.store'

  export let reActivate = false

  let isLoading = false
  let hasError = false

  $: label = reActivate ? $t('stripe.continue_configuration') : $t('stripe.add_account')

  async function connectToStripe() {
    if (isLoading) return
    isLoading = true
    const { accessToken } = (await feathers.get('authentication')) || {}

    if (!accessToken) {
      userStore.logout()
      return
    }
    const res = await fetch(`__ENV_API_URL/stripe-account?$lang=${$localeStore}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        restaurantId: $restaurantIdStore,
      }),
    })

    isLoading = false
    if (!res.ok) {
      hasError = true
      return
    }
    const { url } = (await res.json()) || {}
    if (!url) {
      hasError = true
      return
    }
    window.location = url
  }
</script>

<div class="flex align-center">
  <Button disabled={isLoading} variant="contained" label={label} on:click={connectToStripe} />

  {#if isLoading}
    <Loader size="small" />
  {/if}
</div>

<Snackbar bind:show={hasError} text={$t('errors.generic')} />

<style>
</style>
