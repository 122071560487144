const endpoint = 'https://log.ezyapp.me/error';
export function logError(error) {
    console.error(error);
    if (window.location.host !== 'kool.ezyapp.me' && window.location.host !== 'admin.kool.land') {
        return;
    }
    const errorString = typeof error === 'string'
        ? error
        : error instanceof Error
            ? error.stack
            : 'stack' in error
                ? error.stack
                : '';
    if (!errorString) {
        return;
    }
    void fetch(endpoint, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            error: errorString,
        }),
    }).catch((e) => {
        console.error(e);
    });
}
window.addEventListener('error', (e) => {
    logError(e.error);
});
window.addEventListener('unhandledrejection', (e) => {
    logError(e.reason);
});
