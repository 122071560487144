<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { t } from 'svelte-i18n'
  import CommonForm from '../../app-components/CommonForm.svelte'
  import InfoText from '../../app-components/InfoText.svelte'
  import Button from '../../components/button/Button.svelte'
  import TextField from '../../components/text-field/TextField.svelte'
  import feathers from '../../lib/feathers'
  import type { IChoice } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { restaurantIdStore } from '../../stores/restaurantId.store'

  export let keepInserting = false

  let scrollTarget: HTMLElement | undefined
  const dispatch = createEventDispatcher()

  export let choice: Partial<IChoice> = {
    question: '',
    min: 0,
    max: 0,
    options: [],
  }

  async function handleSave(data: IChoice) {
    const { _id } = data
    data.restaurantId = $restaurantIdStore
    const service = feathers.service('choices')

    const choice = _id ? await service.patch(_id, data) : await service.create(data)

    dispatch('success', choice)
  }

  function addOption() {
    if (choice.options[choice.options.length - 1]?.name === '') {
      return
    }
    choice.options.push({ name: '', price: 0 })
    choice = choice

    if (scrollTarget && 'scrollIntoView' in scrollTarget) {
      try {
        scrollTarget?.scrollIntoView({ behavior: 'smooth' })
      } catch (error) {
        scrollTarget?.scrollIntoView()
      }
    }
  }

  function removeOption(index: number) {
    choice.options = [...choice.options.slice(0, index), ...choice.options.slice(index + 1)]
    choice = choice
  }
</script>

<InfoText>
  <div class="mb-1">{$t('help.choice_help_1')}</div>
  <div class="mb-1">{$t('help.choice_help_2')}</div>
  <div class="mb-1">{$t('help.choice_help_3')}</div>
  <div>{$t('help.choice_help_4')}</div>
</InfoText>
<CommonForm data={choice} bind:keepInserting onSave={handleSave} let:errors>
  <h4 class="mt-4 mb-4 rounded p-2">{$t('form.general_information')}</h4>
  <div class="mb-1">
    <TextField
      label={$t('form.question_label')}
      fullWidth
      inputAttributes={{ name: 'choice-question', required: true }}
      bind:value={choice.question}
      invalid={!!errors?.question}
    />
  </div>
  <TextField
    label={$t('minimum')}
    inputAttributes={{ type: 'number', required: true, max: choice.max }}
    bind:value={choice.min}
    invalid={!!errors?.min}
  />
  <TextField
    label={$t('maximum')}
    inputAttributes={{ type: 'number', required: true, min: 1 }}
    bind:value={choice.max}
    invalid={!!errors?.max}
  />

  <h4 class="mt-4 mb-4 rounded p-2">{$t('form.options_management')}</h4>
  <div>
    <Button label={$t('form.add_option')} variant="contained" on:click={addOption} primary={true} />
    {#each choice.options as option, index}
      <div class="flex align-end mt-2">
        <TextField
          inputAttributes={{ name: 'choice-name' }}
          className="mr-2"
          label={$t('name')}
          bind:value={choice.options[index].name}
        />
        <TextField
          label={$t('price')}
          className="mr-2"
          bind:value={choice.options[index].price}
          inputAttributes={{ type: 'number', min: 0, step: '0.01' }}
        />
        <Button icon="delete" on:click={() => removeOption(index)} primary={!!$darkModeStore} />
      </div>
    {/each}
    <div bind:this={scrollTarget} />
  </div>
</CommonForm>

<style>
</style>
