<script lang="ts">
  import type { Paginated } from '@feathersjs/feathers'
  import { onDestroy, onMount } from 'svelte'
  import { t } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import ColumnOrderAction from '../../app-components/ColumnOrderAction.svelte'
  import OrderDetailPanel from '../../app-components/OrderDetailPanel.svelte'
  import type { IColumn } from '../../components/datatable/Datatable.svelte'
  import Datatable from '../../components/datatable/Datatable.svelte'
  import feathers from '../../lib/feathers'
  import type { Order, OrderStatus } from '../../lib/types'
  import { OrderStatusEnum } from '../../lib/types'
  import { darkModeStore } from '../../stores/darkMode.store'
  import { paginationLimitStore } from '../../stores/paginationLimit.store'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { DELIVERY_TRANSLATIONS, PAYMENT_TRANSLATIONS, STATUS_TRANSLATIONS } from '../../utils/constants'
  import { renderDateOrHours } from '../../utils/orders.utils'

  export let context: PageJS.Context | undefined

  let orders: Paginated<Order>
  $: limit = $paginationLimitStore
  let page = 1
  let sort = { order: -1, key: 'createdAt' }

  $: columns = [
    {
      key: 'reference',
      header: $t('ref'),
      sortable: true,
      renderHtml: (order: Order) => {
        return `#${order.reference}`
      },
    },
    {
      key: 'status',
      header: $t('status'),
      sortable: true,
      renderHtml: (order: Order) => {
        switch (order.status) {
          case 'validated': {
            return `
              <div class="flex items-center orange">
                <span class="material-icons-round mr-4">priority_high</span>
                <span class="">${$t(STATUS_TRANSLATIONS.validated)}</span>
              </div<
              `
          }

          case 'progress': {
            return `
              <div class="flex items-center grey">
                <span class="material-icons-round mr-4">schedule</span>
                <span class="">${$t(STATUS_TRANSLATIONS.progress)}</span>
              </div<
              `
          }

          case 'delivering': {
            return `
              <div class="flex items-center grey">
                <span class="material-icons-round mr-4">local_shipping</span>
                <span class="">${$t(STATUS_TRANSLATIONS.delivering)}</span>
              </div<
              `
          }

          case 'ready': {
            return `
              <div class="flex items-center blue" >
                <span class="material-icons-round mr-4">inventory_2</span>
                <span class="">${$t(STATUS_TRANSLATIONS.ready)}</span>
              </div<
              `
          }

          case 'done': {
            return `
              <div class="flex items-center green" >
                <span class="material-icons-round mr-4">check_circle</span>
                <span class="">${$t(STATUS_TRANSLATIONS.done)}</span>
              </div<
              `
          }

          case 'waitingPayment': {
            return `
              <div class="flex items-center grey" >
                <span class="material-icons-round mr-4">credit_card</span>
                <span class="">${$t(STATUS_TRANSLATIONS.waitingPayment)}</span>
              </div<
              `
          }

          case 'failedPayment': {
            return `
              <div class="flex items-center green" >
                <span class="material-icons-round mr-4">error</span>
                <span class="">${$t(STATUS_TRANSLATIONS.failedPayment)}</span>
              </div<
              `
          }
        }
      },
    },
    {
      key: 'createdAt',
      header: $t('date'),
      sortable: true,
      renderHtml: (order: Order) => {
        return renderDateOrHours(order)
      },
    },

    {
      key: 'price',
      header: $t('price'),
      sortable: true,
      renderString: (line: Order) => {
        return line.price.toFixed(2) + '€'
      },
    },

    {
      key: 'paymentType',
      header: $t('payment'),
      sortable: true,
      renderString: (line: Order) => {
        return $t(PAYMENT_TRANSLATIONS[line.paymentType])
      },
    },

    {
      key: 'delivery',
      header: $t('mode'),
      sortable: true,
      renderString: (line: Order) => {
        return $t(DELIVERY_TRANSLATIONS[line.delivery])
      },
    },
    {
      key: 'waiting',
      header: $t('columns.waiting'),
      sortable: true,
      renderString: (order: Order) => `${order.waiting || '-'}`,
    },

    {
      key: '',
      header: '',
      sortable: false,
      canOverflow: true,
      width: '48px',
      component: ColumnOrderAction,
      componentProps: (order: Order) => ({
        order,
        onEditStatus: async (props: { status: OrderStatus; waiting: number }) => {
          await feathers.service('orders').patch(order._id, {
            status: props.status,
            waiting: props.waiting,
            restaurantId: order.restaurantId,
          })
          const data = await fetchData()
          orders = data
        },
      }),
    },
  ] as IColumn[]

  $: fetchData = async () => {
    const search: any = {}

    return feathers.service('orders').find({
      query: {
        restaurantId: $restaurantIdStore,
        $sort: { [sort.key]: [sort.order] },
        $limit: limit,
        $skip: (page - 1) * limit,
        ...search,
      },
    }) as Promise<Paginated<Order>>
  }

  $: {
    fetchData().then((data) => {
      orders = data
    })
  }

  function onPatch(order: Order) {
    const index = orders.data.findIndex((e) => e._id === order._id)
    if (index === -1) return
    orders.data[index] = order
    orders = orders
  }
  function onCreate(order: Order) {
    if (order.restaurantId !== $restaurantIdStore) return

    window.dispatchEvent(new CustomEvent('play_order_sound'))

    orders.data.push(order)
    orders = orders
  }

  $: hasNewOrder = !!orders?.data?.some((order) => order.status === 'validated')

  $: {
    console.log('hasNewOrder', hasNewOrder)

    if (hasNewOrder) {
      window.dispatchEvent(new CustomEvent('play_order_sound'))
    }
  }

  onMount(() => {
    feathers.service('orders').on('patched', onPatch)
    feathers.service('orders').on('created', onCreate)
  })

  onDestroy(() => {
    feathers.service('orders').removeListener('patched', onPatch)
    feathers.service('orders').removeListener('created', onCreate)
  })

  function getRowClass(order: Order) {
    return order.status === 'validated' ? 'bg-on-background-transparent' : ''
  }
</script>

{#if orders}
  <div in:fade>
    {#if !orders.data}
      <h3>{$t('help.order_empty')}</h3>
    {:else}
      <Datatable
        getRowClass={getRowClass}
        bind:page
        bind:limit={$paginationLimitStore}
        bind:sort
        data={orders.data}
        columns={columns}
        total={orders.total}
        hasDetail={true}
        detailComponent={OrderDetailPanel}
        detailComponentProps={(order) => {
          return { order }
        }}
        buttonsColor={$darkModeStore ? 'primary' : 'default'}
        largeRow
        paginationText={$t('lines_per_page')}
      />
    {/if}
  </div>
{/if}

<datalist id="orderStatuses">
  {#each OrderStatusEnum as status}
    <option value={status} />
  {/each}
</datalist>

<style>
  .orange {
    color: orange;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }
</style>
