<script lang="ts">
  import type { Paginated } from '@feathersjs/feathers'
  import { onMount } from 'svelte'
  import feathers from '../../lib/feathers'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { t } from 'svelte-i18n'
  import Menu from '../../components/menu/Menu.svelte'
  import MenuItem from '../../components/menu/MenuItem.svelte'

  export let context: PageJS.Context | undefined
  export let query:string 
  export let primary

  let categories: Paginated<ICategory>
  async function fetchCategories() {
    const service = feathers.service('categories')
    categories = (await service.find({
      query: { restaurantId: $restaurantIdStore, $limit: 50, $select: 'name' },
    })) as Paginated<ICategory>
  }
  
  let categoryName: string
  $: {
    const urlParams = new URLSearchParams(context?.querystring)
    categoryName = urlParams.get('category')
  }

  console.info("primary",primary)

  onMount(() => fetchCategories())

  // label={categoryName ? `${$t('category')}: ${categoryName}` : $t('category')}
</script>

<Menu
	icon="filter_alt"
	horizontal="left"
	vertical="bottom"
  className={"mr-1"}
	primary={primary}
	>
	{#if categories}
	  <MenuItem label={$t('all')} href={`?`}/>
	  {#each categories.data as category}
	    <MenuItem
	      selected={category.name === categoryName}
	      label={category.name}
	      href={`?category=${category.name}`}
	    />
	  {/each}
	{/if}
</Menu>