<script lang="ts">
  import { fade } from 'svelte/transition'
  import Snackbar from '../../components/snackbar/Snackbar.svelte'
  import type { IRestaurant } from '../../lib/types'
  import { restaurantStore } from '../../stores/restaurant.store'
  import RestaurantForm from './RestaurantForm.svelte'
  import { restaurantIdStore } from '../../stores/restaurantId.store'
  import { t } from 'svelte-i18n'

  export let context: PageJS.Context | undefined

  let success = false

  function handleSuccess(e: CustomEvent<IRestaurant>) {
    success = true
  }
</script>

{#if $restaurantStore}
  {#key $restaurantIdStore}
    <div in:fade>
      <RestaurantForm restaurant={$restaurantStore} on:success={handleSuccess} />

      {#if context?.querystring?.indexOf('stripe-return=1') === 0}
        <Snackbar
          text={$restaurantStore.stripeChargesEnabled ? $t('stripe.enabled_ok') : $t('stripe.configuration_not_finished')}
          show
        />
      {/if}
    </div>
  {/key}

  <Snackbar text={$t('form.save_success')} bind:show={success} />
{/if}

<style>
</style>
